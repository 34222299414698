import React, { type MouseEvent, type ReactNode, memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import { AvatarLite } from '@atlassian/jira-business-avatar-lite/src/index.tsx';
import { openViewFromEmbed } from '@atlassian/jira-business-common/src/common/utils/embeds/index.tsx';
import { SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants/src/index.tsx';
import {
	DONE,
	IN_PROGRESS,
	TODO,
	UNDEFINED,
	statusCategoryForId,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { CardKey } from '@atlassian/jira-platform-card/src/common/ui/key/index.tsx';
import { CardSummary } from '@atlassian/jira-platform-card/src/common/ui/summary/index.tsx';
import { CardType } from '@atlassian/jira-platform-card/src/common/ui/type/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	ISSUE_KEY_ID,
	SUMMARY_ID,
	ISSUE_TYPE_ID,
	STATUS_ID,
	ASSIGNEE_ID,
} from '../../../../../../../common/constants.tsx';
import type { BoardIssue } from '../../../../../../../common/types.tsx';
import { useSubtasksByParentId } from '../../../../../../../controllers/board-issue-store/selectors/index.tsx';
import { useJWMBoardFeatures } from '../../../../../../../controllers/features-context/index.tsx';
import { useViewIssue } from '../../../../../../../controllers/view-issue/index.tsx';
import SubtaskProgressBar from './subtask-progress-bar/index.tsx';

const appearanceMap = {
	[UNDEFINED]: 'default',
	[TODO]: 'default',
	[DONE]: 'success',
	[IN_PROGRESS]: 'inprogress',
} as const;

const MAX_NUMBER_OF_SUBTASKS_WITHOUT_SCROLL = 3;

type SubtaskClickWrapperProps = {
	onClick: (event: MouseEvent<HTMLElement>, subtask: BoardIssue) => void;
	subtask: BoardIssue;
	children: ReactNode;
};

const SubtaskClickWrapper = ({ onClick, subtask, children }: SubtaskClickWrapperProps) => {
	const handleClick = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			onClick(event, subtask);
		},
		[onClick, subtask],
	);

	return (
		<SubtaskWrapper
			data-testid="work-management-board.ui.board.column.card.card-content.subtasks.subtask-list"
			onClick={handleClick}
		>
			{children}
		</SubtaskWrapper>
	);
};

type Props = {
	parent: BoardIssue;
};

const SubtaskList = ({ parent }: Props) => {
	const subtasks = useSubtasksByParentId({ parentId: parent.id });
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { issueViewInteraction } = useJWMBoardFeatures();
	const shouldOpenInNewTab = issueViewInteraction === 'openNewTab';

	const onIssueClick = useViewIssue(null);

	const handleSubtaskClick = useCallback(
		(event: MouseEvent<HTMLElement>, subtaskIssue: BoardIssue) => {
			const withCmd = event.ctrlKey || event.metaKey;
			event.stopPropagation();

			shouldOpenInNewTab
				? openViewFromEmbed({
						key: SELECTED_ISSUE_PARAM,
						value: subtaskIssue.fields[ISSUE_KEY_ID].value,
					})
				: onIssueClick(subtaskIssue, withCmd);
			fireUIAnalytics(createAnalyticsEvent({}), 'subtask clicked');
		},
		[createAnalyticsEvent, onIssueClick, shouldOpenInNewTab],
	);

	const handleIssueKeyClick = useCallback(
		(subtaskIssue: BoardIssue) => {
			shouldOpenInNewTab
				? openViewFromEmbed({
						key: SELECTED_ISSUE_PARAM,
						value: subtaskIssue.fields[ISSUE_KEY_ID].value,
					})
				: onIssueClick(subtaskIssue, false);
		},
		[onIssueClick, shouldOpenInNewTab],
	);

	return (
		<>
			<SubtaskProgressBar subtasks={subtasks} />
			<SubtaskScrollContainer>
				{subtasks.map((subtaskIssue) => (
					<SubtaskClickWrapper
						key={subtaskIssue.id}
						onClick={handleSubtaskClick}
						subtask={subtaskIssue}
					>
						<SubtaskRow>
							<SubtaskSummary>
								<CardSummary text={subtaskIssue.fields[SUMMARY_ID].value} />
							</SubtaskSummary>
						</SubtaskRow>
						<SubtaskRow>
							<SubtaskRowCell>
								<SubtaskIssueTypeContainer>
									<CardType
										uri={subtaskIssue.fields[ISSUE_TYPE_ID].issueType.iconUrl}
										text={subtaskIssue.fields[ISSUE_TYPE_ID].issueType.name}
									/>
								</SubtaskIssueTypeContainer>
								<CardKey
									onClick={() => handleIssueKeyClick(subtaskIssue)}
									text={subtaskIssue.fields[ISSUE_KEY_ID].value}
								/>
							</SubtaskRowCell>
							<SubtaskRowCell>
								<SubtaskLozengeWrapper>
									<Lozenge
										appearance={
											appearanceMap[
												statusCategoryForId(subtaskIssue.fields[STATUS_ID].status.statusCategoryId)
											]
										}
										isBold={isVisualRefreshEnabled()}
									>
										{subtaskIssue.fields[STATUS_ID].status.name}
									</Lozenge>
								</SubtaskLozengeWrapper>
								{!subtaskIssue.fields[ASSIGNEE_ID]?.user.avatarURL ? (
									<Avatar appearance="circle" size="small" />
								) : (
									<AvatarLite
										avatarUrl={subtaskIssue.fields[ASSIGNEE_ID].user.avatarURL}
										name={subtaskIssue.fields[ASSIGNEE_ID].user.name}
									/>
								)}
							</SubtaskRowCell>
						</SubtaskRow>
					</SubtaskClickWrapper>
				))}
			</SubtaskScrollContainer>
			{subtasks.length > MAX_NUMBER_OF_SUBTASKS_WITHOUT_SCROLL && <SubtasksDivider />}
		</>
	);
};

export default memo<Props>(SubtaskList);

const maxCardWidthWithoutPadding = 236;
const borderWidth = 2;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskScrollContainer = styled.div({
	maxHeight: '225px',
	overflowY: 'overlay',
	marginLeft: token('space.negative.150'),
	marginRight: token('space.negative.150'),
	paddingTop: 0,
	paddingBottom: 0,
	paddingLeft: token('space.150'),
	paddingRight: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskWrapper = styled.div({
	marginLeft: token('space.negative.150'),
	marginRight: token('space.negative.150'),
	paddingTop: token('space.075'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.150'),
	paddingRight: token('space.150'),
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskRow = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskRowCell = styled.span({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskIssueTypeContainer = styled.span({
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskSummary = styled.span({
	width: `${maxCardWidthWithoutPadding}px`,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	marginBottom: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtasksDivider = styled.div<{ isTop?: boolean }>({
	width: '100%',
	height: 0,
	margin: 0,
	borderTop: `${borderWidth}px solid ${token('color.border')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ isTop }) => (isTop ? token('space.075') : token('space.0')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtaskLozengeWrapper = styled.div({
	paddingRight: token('space.050'),
	display: 'flex',
});

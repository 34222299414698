/**
 * @generated SignedSource<<b170fadf0055783b18cb27f76017064c>>
 * @relayHash 2da4ae7661b646746b18386f70f3c831
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e27d6da23b42336bf9e89a0160f29b3a79e15d77df77ca2bb5b6865f0bcc3fda

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraBoardViewInput = {
  jiraBoardViewQueryInput: JiraBoardViewQueryInput;
  settings?: JiraBoardViewSettings | null | undefined;
};
export type JiraBoardViewQueryInput = {
  projectKeyAndItemIdQuery?: JiraBoardViewProjectKeyAndItemIdQuery | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraBoardViewProjectKeyAndItemIdQuery = {
  cloudId: string;
  itemId: string;
  projectKey: string;
};
export type JiraBoardViewSettings = {
  filterJql?: string | null | undefined;
  groupBy?: string | null | undefined;
};
export type BoardMoreMenuContentQuery$variables = {
  projectAri: string;
  viewInput: JiraBoardViewInput;
  withMergedWorkflows: boolean;
};
export type BoardMoreMenuContentQuery$data = {
  readonly jira: {
    readonly jiraProject: {
      readonly " $fragmentSpreads": FragmentRefs<"WorkflowSelectorMenu_project">;
    } | null | undefined;
  } | null | undefined;
  readonly jira_boardView: {
    readonly " $fragmentSpreads": FragmentRefs<"CardFieldsMenu" | "ExpandCollapseAll_view" | "RemoveResolvedItemsMenu" | "WorkflowSelectorMenu_view">;
  };
};
export type BoardMoreMenuContentQuery = {
  response: BoardMoreMenuContentQuery$data;
  variables: BoardMoreMenuContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "withMergedWorkflows"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "viewInput"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "BoardMoreMenuContentQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              {
                "condition": "withMergedWorkflows",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "WorkflowSelectorMenu_project"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "RequiredField",
        "field": {
          "args": (v2/*: any*/),
          "concreteType": "JiraBoardView",
          "kind": "LinkedField",
          "name": "jira_boardView",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "CardFieldsMenu"
            },
            {
              "condition": "withMergedWorkflows",
              "kind": "Condition",
              "passingValue": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "WorkflowSelectorMenu_view"
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "RemoveResolvedItemsMenu"
            },
            {
              "kind": "FragmentSpread",
              "name": "ExpandCollapseAll_view"
            }
          ]
        },
        "action": "THROW",
        "path": "jira_boardView"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardMoreMenuContentQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              {
                "condition": "withMergedWorkflows",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "projectStyle"
                  },
                  {
                    "alias": "canAdministerProject",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "EDIT_PROJECT_CONFIG"
                      }
                    ],
                    "concreteType": "JiraProjectAction",
                    "kind": "LinkedField",
                    "name": "action",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "canPerform"
                      }
                    ],
                    "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                  }
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      },
      {
        "args": (v2/*: any*/),
        "concreteType": "JiraBoardView",
        "kind": "LinkedField",
        "name": "jira_boardView",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "menuCardOptions",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 200
              }
            ],
            "concreteType": "JiraBoardViewCardOptionConnection",
            "kind": "LinkedField",
            "name": "cardOptions",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraBoardViewCardOptionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraField",
                            "kind": "LinkedField",
                            "name": "field",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "name"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "fieldId"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "typeKey"
                              },
                              (v3/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "canToggle"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "enabled"
                          }
                        ],
                        "type": "JiraBoardViewFieldCardOption"
                      },
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ],
            "storageKey": "cardOptions(first:200)"
          },
          {
            "kind": "ScalarField",
            "name": "completedIssueSearchCutOffInDays"
          },
          {
            "concreteType": "JiraBoardViewColumnConnection",
            "kind": "LinkedField",
            "name": "columns",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ]
          },
          {
            "condition": "withMergedWorkflows",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "selectedWorkflowId"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e27d6da23b42336bf9e89a0160f29b3a79e15d77df77ca2bb5b6865f0bcc3fda",
    "metadata": {},
    "name": "BoardMoreMenuContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "147180b29613be81c66264f73f45a4d0";

export default node;

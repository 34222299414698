/** @jsx jsx */
import React, { memo, useCallback, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { Transition } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ColumnTransitionZonesContainer } from '@atlassian/jira-platform-board-kit/src/ui/column/column-transition-zones-container/main.tsx';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants/src/index.tsx';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { BoardTransitionZones_query$key } from '@atlassian/jira-relay/src/__generated__/BoardTransitionZones_query.graphql';
import { COLUMN_HEADER_HEIGHT, TRANSITION_ZONE_MIN_HEIGHT } from '../../../../common/constants.tsx';
import type { Group } from '../../../../common/types.tsx';
import {
	useCardDragDrop,
	useUpdateDropTarget,
	useCancelConfirmCardDrop,
} from '../../../../controllers/drag-and-drop/card-drag-drop-context/index.tsx';
import { useMoveIssue } from '../../../../controllers/move-issue/index.tsx';
import { useIssueTransitions } from '../../../../controllers/issue-transitions/index.tsx';
import messages from './messages.tsx';

const getTransitionById = (transitions: Transition[], transitionId: number | null) =>
	transitions.find((transition) => transition.transitionId === transitionId);

type Props = {
	queryFragment: BoardTransitionZones_query$key;
	dragAndDropColumnData: Group;
};

const BoardTransitionZones = ({ queryFragment, dragAndDropColumnData }: Props) => {
	let categoryField: {
		fieldId: string;
		displayName: string;
	} | null = null;
	if (fg('sv-357_relay_category_field')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const data = useFragment(
			graphql`
				fragment BoardTransitionZones_query on Query
				@argumentDefinitions(
					withCategoryField: {
						type: "Boolean!"
						provider: "@atlassian/jira-relay-provider/src/relay-category-field.relayprovider"
					}
				) {
					jira_categoryField(cloudId: $cloudId) @include(if: $withCategoryField) {
						fieldId @required(action: THROW)
						displayName @required(action: THROW)
					}
				}
			`,
			queryFragment,
		);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		categoryField = useMemo(
			() =>
				data.jira_categoryField
					? {
							fieldId: data.jira_categoryField.fieldId,
							displayName: data.jira_categoryField.displayName,
						}
					: null,
			[data.jira_categoryField],
		);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { data: categoryFieldData } = useCategoryField();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		categoryField = useMemo(
			() =>
				categoryFieldData
					? {
							fieldId: categoryFieldData.id,
							displayName: categoryFieldData.name,
						}
					: null,
			[categoryFieldData],
		);
	}

	const { transitions } = useIssueTransitions();
	const updateDropTarget = useUpdateDropTarget();
	const moveIssue = useMoveIssue();
	const { draggedIssue, sourceGroup, dropTarget } = useCardDragDrop();
	const { confirmCardDrop } = useCancelConfirmCardDrop();

	const handleCardDragOver = useCallback(
		(transitionId: number | null) => {
			updateDropTarget({
				group: dragAndDropColumnData,
				transition: getTransitionById(transitions, transitionId),
			});
		},
		[dragAndDropColumnData, transitions, updateDropTarget],
	);

	const handleDroppedOnTransition = useCallback(
		(transitionId: number | null) => {
			if (!draggedIssue) {
				return;
			}
			const transition = getTransitionById(transitions, transitionId);
			updateDropTarget({
				group: dragAndDropColumnData,
				transition,
			});

			moveIssue({
				issue: draggedIssue,
				sourceGroup,
				destinationGroup: dragAndDropColumnData,
				transition,
				categoryField,
			});
			confirmCardDrop();
		},
		[
			dragAndDropColumnData,
			transitions,
			updateDropTarget,
			draggedIssue,
			moveIssue,
			confirmCardDrop,
			sourceGroup,
			categoryField,
		],
	);

	const sourceStatus = useMemo(
		() =>
			sourceGroup
				? {
						id: Number(sourceGroup.id),
						name: sourceGroup.name,
					}
				: undefined,
		[sourceGroup],
	);

	const statusCategoryId =
		dragAndDropColumnData.type === GROUP_BY_STATUS ? dragAndDropColumnData.statusCategoryId : 0;

	const transitionZonesData = useMemo(
		() =>
			transitions
				.filter((transition) => String(transition.toStatusId) === dragAndDropColumnData.id)
				.map((transition) => ({
					transitionId: transition.transitionId,
					transitionName: transition.name,
					targetStatusId: transition.toStatusId,
					targetStatusName: dragAndDropColumnData.name,
					targetStatusCategory: statusCategoryForId(statusCategoryId),
					isAvailable: true,
					isGlobal: transition.isGlobal,
				})),
		[transitions, dragAndDropColumnData, statusCategoryId],
	);

	if (!sourceGroup) {
		return null;
	}

	return (
		<div css={transitionZoneWrapperStyles}>
			<ColumnTransitionZonesContainer
				hasSwimlanes={false}
				columnId={dragAndDropColumnData.id}
				sourceColumnId={sourceGroup.id}
				sourceStatus={sourceStatus}
				transitions={transitionZonesData}
				hoverIntent={false}
				columnDraggingOver={dropTarget?.group?.id ?? null}
				notAvailableMessage={
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.notAvailableMessageIssueTermRefresh
						: messages.notAvailableMessage
				}
				onCardDragOver={handleCardDragOver}
				onTransitionSelected={handleDroppedOnTransition}
			/>
		</div>
	);
};

export default memo(BoardTransitionZones);

const transitionZoneWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.outer-transition-container"]':
		{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			height: `calc(100% - ${COLUMN_HEADER_HEIGHT}px)`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.inner-container"]':
		{
			maxHeight: 'none',
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.transition-zone.compiled-transition-zones-container"]':
		{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			minHeight: `${TRANSITION_ZONE_MIN_HEIGHT}px`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.transition-to-zone.transition-to-zone"]':
		{
			marginTop: 0,
			position: 'absolute',
			top: '5px',
		},
});

import uniqBy from 'lodash/uniqBy';
import {
	CATEGORY_TYPE,
	SPRINT_TYPE,
	FIX_VERSIONS_TYPE,
	PARENT_FIELD_TYPE,
	COMPONENTS_TYPE,
	GOALS_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type {
	SelectValue,
	AvatarOption,
	SelectOption,
	PriorityOption,
	LozengeOption,
	IssueTypeOption,
	CategoryOption,
	GoalOption,
} from '../../types.tsx';
import type {
	IssueTypeFieldValues,
	FieldValues,
	PriorityFieldValues,
	UserFieldValues,
	SprintFieldValues,
	StatusFieldValues,
	IssueTypeNode,
	DefaultFieldValues,
	CategoryFieldValues,
	CategoryEdge,
	TransformData,
	TransformedInitialData,
	GoalsFieldValues,
	ParentFieldValues,
	TeamFieldValues,
} from './types.tsx';

const getFirstIssueTypeAvatar = (node: IssueTypeNode) =>
	node.issueTypes?.find((issueType) => issueType?.avatar?.medium != null)?.avatar?.medium;

const getFirstIssueTypeId = (node: IssueTypeNode) => node.issueTypes[0]?.issueTypeId;

const getFirstIssueTypeHierarchyLevel = (node: IssueTypeNode) =>
	node.issueTypes[0]?.hierarchy?.level;

export const transformPriorities = (data: PriorityFieldValues): SelectValue<PriorityOption> =>
	data.edges.map(({ node, cursor }) => ({
		cursor,
		value: node.jqlTerm,
		label: node.displayName,
		avatar: node.priority.iconUrl || '',
	}));

export const transformUsers = (data: UserFieldValues): SelectValue<AvatarOption> =>
	data.edges
		.filter(({ node }) => node?.user != null)
		.map(({ node, cursor }) => ({
			cursor,
			value: node.jqlTerm,
			label: node.displayName,
			avatar: node?.user?.picture || '',
		}));

export const transformIssueTypes = (data: IssueTypeFieldValues): SelectValue<IssueTypeOption> =>
	data.edges.map(({ node, cursor }) => ({
		cursor,
		value: node.jqlTerm,
		label: node.displayName,
		id: getFirstIssueTypeId(node),
		avatar: getFirstIssueTypeAvatar(node) || '',
		square: true,
		hierarchyLevel: getFirstIssueTypeHierarchyLevel(node) || 0,
	}));

export const transformStatuses = (data: StatusFieldValues): SelectValue<LozengeOption> =>
	data.edges.map(({ node, cursor }) => ({
		cursor,
		value: node.jqlTerm,
		label: node.displayName,
		categoryId: node.statusCategory.statusCategoryId,
	}));

export const transformCategories = (data: CategoryFieldValues): SelectValue<CategoryOption> => {
	const transformedCategories = data.edges.map((edge) => ({
		cursor: edge?.cursor,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		value: (edge as CategoryEdge).node.option.value,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		label: (edge as CategoryEdge).node.option.value,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		color: (edge as CategoryEdge).node.color,
	}));

	// Remove any categories that have the same name across multiple projects
	return uniqBy(transformedCategories, ({ value }) => value);
};

export const transformDefaultField = (data: DefaultFieldValues): SelectValue<SelectOption> =>
	data.edges.map(({ node, cursor }) => ({
		cursor,
		value: node.jqlTerm,
		label: node.displayName,
	}));

export const transformSprints = (data: SprintFieldValues): SelectValue<SelectOption> =>
	data.edges
		.filter(({ node }) => node?.sprint != null)
		.map(({ node, cursor }) => ({
			cursor,
			value: node.displayName,
			label: node.sprint?.name || '',
		}));

export const transformGoals = (data: GoalsFieldValues): SelectValue<GoalOption> =>
	data.edges.map(({ node, cursor }) => ({
		cursor,
		value: node.jqlTerm,
		label: node.goal.name || '',
		status: node.goal.status || '',
	}));

export const transformParent = (data: ParentFieldValues): SelectValue<AvatarOption> =>
	data?.edges.map(({ node, cursor }) => {
		let avatar = '';
		if (node.issue?.fieldsByIdOrAlias && node.issue.fieldsByIdOrAlias.length > 0) {
			avatar = node.issue.fieldsByIdOrAlias[0].issueType?.avatar?.medium || '';
		}
		return {
			cursor,
			value: node.jqlTerm,
			label: node.displayName,
			avatar,
			...(isVisualRefreshEnabled() && { square: true }),
		};
	});

export const transformTeams = (data: TeamFieldValues): SelectValue<AvatarOption> =>
	data?.edges
		.filter(({ node }) => node?.team != null)
		.map(({ node }) => ({
			cursor: data.pageInfo?.endCursor,
			value: node.team.id.replace('ari:cloud:identity::team/', ''),
			label: node.team.displayName || '',
			avatar: node.team.smallAvatarImageUrl || '',
		}));

// Type predicted
const isIssueType = (fieldValue: FieldValues): fieldValue is IssueTypeFieldValues => {
	const { node } = fieldValue.edges[0];
	return Boolean(node && 'issueTypes' in node);
};

const isPriority = (fieldValue: FieldValues): fieldValue is PriorityFieldValues => {
	const { node } = fieldValue.edges[0];
	return Boolean(node && 'priority' in node);
};

const isStatus = (fieldValue: FieldValues): fieldValue is StatusFieldValues => {
	const { node } = fieldValue.edges[0];
	return Boolean(node && 'statusCategory' in node);
};

const isSprint = (fieldValue: FieldValues): fieldValue is SprintFieldValues => {
	const { node } = fieldValue.edges[0];
	return Boolean(node && 'sprint' in node);
};

const isGoal = (fieldValue: FieldValues): fieldValue is GoalsFieldValues => {
	const { node } = fieldValue.edges[0];
	return Boolean(node && 'goal' in node);
};

const isParent = (fieldValue: FieldValues): fieldValue is ParentFieldValues => {
	const { node } = fieldValue.edges[0];
	return Boolean(node && 'issue' in node);
};

// any fieldValue could be a UserFieldValues because the edges could have some node with "user" undefined
// for example : { node: { displayName: "administrators", jqlTerm: "membersOf(administrators)" }}
const isUser = (fieldValue: FieldValues): fieldValue is UserFieldValues => true;

const isDefaultField = (fieldValue: FieldValues): fieldValue is DefaultFieldValues =>
	Boolean(fieldValue.edges[0]);

export const EMPTY_TRANSFORMED_DATA: TransformedInitialData = {
	labels: { totalCount: 0, options: [] },
	components: { totalCount: 0, options: [] },
	priority: { totalCount: 0, options: [] },
	users: { totalCount: 0, options: [] },
	issuetype: { totalCount: 0, options: [] },
	status: { totalCount: 0, options: [] },
	[CATEGORY_TYPE]: { totalCount: 0, options: [] },
	[SPRINT_TYPE]: { totalCount: 0, options: [] },
	[FIX_VERSIONS_TYPE]: { totalCount: 0, options: [] },
	[PARENT_FIELD_TYPE]: { totalCount: 0, options: [] },
	[GOALS_CF_TYPE]: { totalCount: 0, options: [] },
	[TEAMS_PLATFORM_CF_TYPE]: { totalCount: 0, options: [] },
};

export const transformValuesData = (data: TransformData): TransformedInitialData => {
	const transformedData = { ...EMPTY_TRANSFORMED_DATA };

	Object.entries(data).forEach(([key, fieldValue]) => {
		if (
			fieldValue &&
			fieldValue.edges &&
			Array.isArray(fieldValue.edges) &&
			fieldValue.edges.length
		) {
			switch (key) {
				case 'issuetype': {
					if (isIssueType(fieldValue)) {
						transformedData.issuetype = {
							totalCount: fieldValue.totalCount,
							options: transformIssueTypes(fieldValue),
						};
					}
					break;
				}
				case 'priority': {
					if (isPriority(fieldValue)) {
						transformedData.priority = {
							totalCount: fieldValue.totalCount,
							options: transformPriorities(fieldValue),
						};
					}
					break;
				}
				case 'status': {
					if (isStatus(fieldValue)) {
						transformedData.status = {
							totalCount: fieldValue.totalCount,
							options: transformStatuses(fieldValue),
						};
					}
					break;
				}
				case 'component': // Key from hydrate-values
				case 'components':
				case 'fixVersions':
				case 'labels': {
					if (isDefaultField(fieldValue)) {
						const transformedKey = key === 'component' ? COMPONENTS_TYPE : key;
						transformedData[transformedKey] = {
							totalCount: fieldValue.totalCount,
							options: transformDefaultField(fieldValue),
						};
					}
					break;
				}
				case 'parent': {
					if (isParent(fieldValue)) {
						transformedData[key] = {
							totalCount: fieldValue.totalCount,
							options: transformParent(fieldValue),
						};
					}
					break;
				}
				default:
					if (isSprint(fieldValue)) {
						transformedData[SPRINT_TYPE] = {
							totalCount: fieldValue.totalCount,
							options: transformSprints(fieldValue),
						};
					} else if (
						fieldValue.edges.some((item) => item.node && 'user' in item.node) &&
						isUser(fieldValue)
					) {
						const usersData = transformUsers(fieldValue);
						const userOptions = uniqBy(
							[...usersData, ...(transformedData.users?.options ?? [])],
							({ value }) => value,
						);
						transformedData.users = {
							totalCount: userOptions.length,
							options: userOptions,
						};
					} else if (isGoal(fieldValue)) {
						transformedData[GOALS_CF_TYPE] = {
							totalCount: fieldValue.totalCount,
							options: transformGoals(fieldValue),
						};
					}
			}
		}
	});

	return transformedData;
};

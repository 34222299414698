/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { formatInTimeZone } from 'date-fns-tz';
import { format, toDate } from 'date-fns';
import { fg } from '@atlassian/jira-feature-gating';
import {
	START_DATE_ALIAS_FIELD_ID,
	STORY_POINTS_ALIAS_FIELD_ID,
} from '@atlassian/jira-business-constants/src/index.tsx';
import type { GenericIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import type { Payload } from '@atlassian/jira-issue-create/src/common/types/index.tsx';
import {
	ASSIGNEE_TYPE,
	CATEGORY_TYPE,
	DUE_DATE_TYPE,
	NUMBER_CF_TYPE,
	PRIORITY_TYPE,
	SPRINT_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SUMMARY_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { CreateIssueDialogInput, ExtraCreateIssuePayload } from '../../types.tsx';
import type { GetField } from './types.tsx';

const assigneeFieldPredicate = (field: GenericIssueField) => field.type === ASSIGNEE_TYPE;

const categoryFieldPredicate = (field: GenericIssueField) => field.type === CATEGORY_TYPE;

const dueDateFieldPredicate = (field: GenericIssueField) => field.type === DUE_DATE_TYPE;

const priorityFieldPredicate = (field: GenericIssueField) => field.type === PRIORITY_TYPE;

const startDateFieldPredicate = (field: GenericIssueField) =>
	field.aliasFieldId === START_DATE_ALIAS_FIELD_ID;

const storyPointEstimateFieldPredicate = (field: GenericIssueField) =>
	field.type === STORY_POINT_ESTIMATE_CF_TYPE;

const storyPointsFieldPredicate = (field: GenericIssueField) =>
	field.type === NUMBER_CF_TYPE && field.aliasFieldId === STORY_POINTS_ALIAS_FIELD_ID;

const sprintFieldPredicate = (field: GenericIssueField) => field.type === SPRINT_TYPE;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AdditionalFields = { [key: string]: any };
type Transition = { id: string };

type Arguments = {
	createInput: CreateIssueDialogInput;
	extraPayload: ExtraCreateIssuePayload | undefined;
	rankFieldId: string | null;
	getField: GetField;
};

type Return = {
	payload: Payload;
	submitApiData: { additionalFields: AdditionalFields; transition?: Transition };
};

export const buildGICPayload = ({
	createInput,
	extraPayload,
	rankFieldId,
	getField,
}: Arguments): Return => {
	const payload: Payload = {
		defaultValues: {
			singleLineTextFields: [],
			singleSelectUserPickerFields: [],
			numberFields: [],
			datePickerFields: [],
		},
		project: {
			projectId: createInput.projectId,
		},
	};

	const additionalFields: AdditionalFields = {};
	let transition: Transition | undefined;

	if (createInput.summary) {
		payload.defaultValues!.singleLineTextFields.push({
			fieldId: SUMMARY_TYPE,
			text: createInput.summary,
		});
	}

	if (createInput.issueType) {
		payload.issueType = {
			issueTypeId: createInput.issueType.id,
		};
	}

	if (createInput.dueDate && getField(createInput.issueType, dueDateFieldPredicate)) {
		const formattedDueDate = fg('change-timezone-formatting-in-duedate-for-icc')
			? format(toDate(createInput.dueDate), 'yyyy-MM-dd')
			: formatInTimeZone(createInput.dueDate, 'UTC', 'yyyy-MM-dd');

		payload.defaultValues!.datePickerFields.push({
			fieldId: DUE_DATE_TYPE,
			date: { formattedDate: formattedDueDate },
		});

		additionalFields.duedate = formattedDueDate;
	}

	const startDateField = getField(createInput.issueType, startDateFieldPredicate);
	if (createInput.startDate && startDateField) {
		const formattedStartDate = formatInTimeZone(createInput.startDate, 'UTC', 'yyyy-MM-dd');

		payload.defaultValues!.datePickerFields.push({
			fieldId: startDateField.id,
			date: { formattedDate: formattedStartDate },
		});

		additionalFields[startDateField.id] = formattedStartDate;
	}

	if (createInput.assignee && getField(createInput.issueType, assigneeFieldPredicate)) {
		payload.defaultValues!.singleSelectUserPickerFields.push({
			fieldId: ASSIGNEE_TYPE,
			user: {
				accountId: createInput.assignee.accountId,
			},
		});

		additionalFields[ASSIGNEE_TYPE] = {
			id: createInput.assignee.accountId,
		};
	}

	if (createInput.priority && getField(createInput.issueType, priorityFieldPredicate)) {
		payload.defaultValues!.priority = { priorityId: createInput.priority.priorityId };

		additionalFields[PRIORITY_TYPE] = {
			id: createInput.priority.priorityId,
		};
	}

	const categoryField = getField(createInput.issueType, categoryFieldPredicate);
	if (createInput.category && categoryField) {
		payload.defaultValues!.singleSelectFields = [
			{
				fieldId: categoryField.id,
				option: {
					id: createInput.category.optionId,
					optionId: createInput.category.optionId,
				},
			},
		];

		additionalFields[categoryField.id] = {
			id: createInput.category.optionId,
			value: createInput.category.value,
		};
	}

	const storyPointEstimateField = getField(createInput.issueType, storyPointEstimateFieldPredicate);
	if (createInput.storyPointEstimate && storyPointEstimateField) {
		payload.defaultValues!.numberFields.push({
			fieldId: storyPointEstimateField.id,
			value: createInput.storyPointEstimate,
		});

		additionalFields[storyPointEstimateField.id] = {
			id: storyPointEstimateField.id,
			value: createInput.storyPointEstimate,
		};
	}

	const storyPointsField = getField(createInput.issueType, storyPointsFieldPredicate);
	if (createInput.storyPoints && storyPointsField) {
		payload.defaultValues!.numberFields.push({
			fieldId: storyPointsField.id,
			value: createInput.storyPoints,
		});

		additionalFields[storyPointsField.id] = {
			id: storyPointsField.id,
			value: createInput.storyPoints,
		};
	}

	const sprintField = getField(createInput.issueType, sprintFieldPredicate);
	if (createInput.sprint && sprintField) {
		payload.defaultValues!.sprintsField = {
			fieldId: sprintField.id,
			sprints: [{ sprintId: createInput.sprint.id }],
		};

		additionalFields[sprintField.id] = {
			id: sprintField.id,
			value: createInput.sprint.id,
		};
	}

	if (createInput.parentIssueId) {
		payload.parentId = {
			parentId: createInput.parentIssueId,
		};
		payload.defaultValues!.parentField = {
			issueId: createInput.parentIssueId,
		};
	}

	if (createInput.status && extraPayload?.transitionId) {
		payload.status = {
			statusId: createInput.status.statusId,
		};

		transition = { id: extraPayload.transitionId };
	}

	if (rankFieldId && (createInput.rankAfter || createInput.rankBefore)) {
		// rank is always passed as additional field
		additionalFields[rankFieldId] = {
			...(createInput.rankAfter ? { rankAfterIssue: createInput.rankAfter.issueId } : {}),
			...(createInput.rankBefore ? { rankBeforeIssue: createInput.rankBefore.issueId } : {}),
		};
	}

	return { payload, submitApiData: { additionalFields, transition } };
};

import get from 'lodash/get';
import memoizeOne from 'memoize-one';
import type { JWMView } from '@atlassian/jira-business-common/src/common/types/jwm-view.tsx';
import type { BusinessIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import type { UserResolver } from '@atlassian/jira-business-fetch-users/src/services/get-users/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShape as Intl } from '@atlassian/jira-intl';
import type { User } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import {
	ASSIGNEE_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	CREATED,
	FIX_VERSIONS_TYPE,
	GOALS_CF_TYPE,
	ISSUE_TYPE,
	LABELS_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	PARENT_FIELD_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	PROJECT_TYPE,
	REPORTER_TYPE,
	SELECT_CF_TYPE,
	SPRINT_TYPE,
	STATUS_TYPE,
	UPDATED,
	USER_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { FetchTeamsById } from '../../services/fetch-teams/index.tsx';
import { DATE_RANGE_TYPE } from '../../common/constants.tsx';
import {
	type IssueTypeOption,
	type TransformedFieldData,
	type AvatarOption,
	type SelectOption,
	FieldType,
	type FieldConfig,
} from '../../common/types.tsx';
import { EMPTY_FILTER_VALUE } from '../../constants.tsx';
import type { FetchOptionsV2, Resolvers, Option } from '../types.tsx';
import messages from './messages.tsx';
import type { FieldConfigType } from './types.tsx';

type OptionWithAvatar = Option & { avatar: string };

export const transformUsers = memoizeOne((users?: User[] | null): OptionWithAvatar[] => {
	if (
		(users == null || !Array.isArray(users)) &&
		fg('prevent_user_transformation_on_non-array_input')
	) {
		return [];
	}

	// Remove this lint and ts ignore when 'prevent_user_transformation_on_non-array_input' gets clean up
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return users.reduce<Array<OptionWithAvatar>>((acc, next) => {
		acc.push({
			value: next.accountId || '',
			label: next.displayName,
			avatar: get(next, ['avatarUrls', '24x24'], ''),
		});
		return acc;
	}, []);
});

const getType = (type: string): FieldConfigType => {
	switch (type) {
		case CATEGORY_TYPE:
			return FieldType.CategorySelectFilter;
		case PRIORITY_TYPE:
			return FieldType.PrioritySelectFilter;
		case ASSIGNEE_TYPE:
		case REPORTER_TYPE:
		case USER_CF_TYPE:
			return FieldType.AvatarAsyncSelectFilter;
		case ISSUE_TYPE:
		case PARENT_FIELD_TYPE:
		case TEAMS_PLATFORM_CF_TYPE:
			return FieldType.AvatarSelectFilter;
		case STATUS_TYPE:
			return FieldType.LozengeSelectFilter;
		case LABELS_TYPE:
		case COMPONENTS_TYPE:
		case FIX_VERSIONS_TYPE:
		case SPRINT_TYPE:
			return FieldType.AsyncSelectFilter;
		case CREATED:
		case UPDATED:
			return FieldType.DateFilter;
		case PROJECT_TYPE:
			return FieldType.ProjectFilter;
		case GOALS_CF_TYPE:
			return FieldType.GoalsSelectFilter;
		default:
			return FieldType.SelectFilter;
	}
};

const getValues = (
	type: string,
	fieldOptionsData: {
		[key: string]: TransformedFieldData<AvatarOption>;
	},
) => {
	switch (type) {
		case ASSIGNEE_TYPE:
		case REPORTER_TYPE:
		case USER_CF_TYPE:
			return fieldOptionsData.users;
		default:
			return fieldOptionsData[type];
	}
};

const isUserField = (type: string) => {
	switch (type) {
		case ASSIGNEE_TYPE:
		case REPORTER_TYPE:
		case USER_CF_TYPE:
			return true;
		default:
			return false;
	}
};

const isCustomField = (type: string) => {
	switch (type) {
		case USER_CF_TYPE:
		case PEOPLE_CF_TYPE:
		case SELECT_CF_TYPE:
		case MULTI_SELECT_CF_TYPE:
		case MULTI_CHECKBOXES_CF_TYPE:
			return true;
		default:
			return false;
	}
};

const createUserValueGetter = (userResolver: UserResolver) =>
	memoizeOne((accountIds: string[]) =>
		userResolver
			.getUsersFromAccountId(accountIds) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then((response: any) => transformUsers(response)),
	);

const createTeamValueGetter = ({
	fetchTeamsById,
	options,
}: {
	fetchTeamsById: FetchTeamsById;
	options: SelectOption[];
}) =>
	memoizeOne((teamIds: string[]) => {
		// if the team is already loaded not fetch the team data
		const teamsLoadedOptions = options.filter((option) =>
			option.value ? teamIds.includes(option.value) : false,
		);
		if (teamsLoadedOptions.length === teamIds.length) {
			return teamsLoadedOptions;
		}
		const loadedTeamIds = teamsLoadedOptions.map((option) => option.value);
		const teamIdsToFetch = teamsLoadedOptions.length
			? teamIds.filter((teamId) => !loadedTeamIds.includes(teamId))
			: teamIds;

		return fetchTeamsById({ teamIds: teamIdsToFetch });
	});

export const getFilterFieldConfigAndResolvers = ({
	view,
	formatMessage,
	allowedFieldTypes,
	allFields,
	fieldOptionsData,
	userResolver,
	fetchUsers,
	fetchLabels,
	fetchComponents,
	fetchCategories,
	fetchStatuses,
	fetchPriorities,
	fetchIssueTypes,
	fetchSprints,
	fetchFixVersions,
	fetchParents,
	fetchGoals,
	fetchTeams,
	fetchTeamsById,
}: {
	view?: JWMView;
	formatMessage: Intl['formatMessage'];
	allowedFieldTypes: Set<string>;
	allFields: BusinessIssueField[];
	fieldOptionsData: {
		[key: string]: TransformedFieldData<SelectOption>;
	};
	userResolver: UserResolver;
	fetchUsers: FetchOptionsV2;
	fetchLabels: FetchOptionsV2;
	fetchComponents: FetchOptionsV2;
	fetchCategories: FetchOptionsV2;
	fetchStatuses?: FetchOptionsV2;
	fetchPriorities?: FetchOptionsV2;
	fetchIssueTypes?: FetchOptionsV2;
	fetchSprints?: FetchOptionsV2;
	fetchFixVersions?: FetchOptionsV2;
	fetchParents?: FetchOptionsV2;
	fetchGoals?: FetchOptionsV2;
	fetchTeams?: FetchOptionsV2;
	fetchTeamsById?: FetchTeamsById;
}): {
	fieldConfig: FieldConfig;
	resolvers: Resolvers;
} => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const fieldConfig: Record<string, any> = {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const resolvers: Record<string, any> = {};

	if (allowedFieldTypes.has(DATE_RANGE_TYPE)) {
		// manually add dateRange to fieldConfig
		fieldConfig.dateRange = {
			label: formatMessage(messages.dateRangeLabel),
			type: FieldType.DateFilter,
			fromLabel: formatMessage(messages.startDateLabel),
			toLabel: formatMessage(messages.dueDateLabel),
		};
	}

	const fields = allFields.filter((field) => allowedFieldTypes.has(field.type ?? ''));
	fields.forEach((field) => {
		const { name, type, id } = field;
		const fieldType = type ?? '';
		const fieldValues = getValues(fieldType, fieldOptionsData);

		fieldConfig[id] = {
			label: name,
			type: getType(fieldType),
			placeholder: formatMessage(messages.filterPlaceholder),
			options: fieldValues?.options || [],
			totalCount: fieldValues?.totalCount || 0,
			isCustomField: isCustomField(fieldType),
			fieldConfigVersion: 2,
		};

		// issue type
		if (type === ISSUE_TYPE) {
			let options = fieldValues?.options || [];
			if (view === 'board') {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				options = (fieldValues?.options as IssueTypeOption[]).filter(
					(option) => option.hierarchyLevel !== -1,
				);
				fieldConfig[id].options = options;
			}
			if (fetchIssueTypes) {
				fieldConfig[id].loadOptions = fetchIssueTypes;
				fieldConfig[id].defaultOptions = options;
			}
		}
		// user
		else if (isUserField(fieldType)) {
			fieldConfig[id].loadOptions = fetchUsers;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
			resolvers[id] = createUserValueGetter(userResolver);
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.unassignedLabel),
					isEmptyOption: true,
				},
			];
			fieldConfig[id].totalCount += 1;
		}
		// label
		else if (fieldType === LABELS_TYPE) {
			fieldConfig[id].loadOptions = fetchLabels;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.noLabelsLabel),
					isEmptyOption: true,
				},
			];
			fieldConfig[id].totalCount += 1;
		}
		// component
		else if (fieldType === COMPONENTS_TYPE) {
			fieldConfig[id].loadOptions = fetchComponents;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.noComponentsLabel),
					isEmptyOption: true,
				},
			];
			fieldConfig[id].totalCount += 1;
		}
		// category
		else if (fieldType === CATEGORY_TYPE) {
			fieldConfig[id].loadOptions = fetchCategories;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.noCategoriesLabel),
					isEmptyOption: true,
				},
			];
			fieldConfig[id].totalCount += 1;
		}
		// status
		else if (fieldType === STATUS_TYPE && fetchStatuses) {
			fieldConfig[id].loadOptions = fetchStatuses;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
		}
		// priority
		else if (fieldType === PRIORITY_TYPE && fetchPriorities) {
			fieldConfig[id].loadOptions = fetchPriorities;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
		}
		// sprint
		else if (fieldType === SPRINT_TYPE && fetchSprints) {
			fieldConfig[id].loadOptions = fetchSprints;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.noSprintLabel),
					isEmptyOption: true,
				},
			];
			fieldConfig[id].totalCount += 1;
		}
		// fix version
		else if (fieldType === FIX_VERSIONS_TYPE && fetchFixVersions) {
			fieldConfig[id].loadOptions = fetchFixVersions;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.noVersionLabel),
					isEmptyOption: true,
				},
			];
			fieldConfig[id].totalCount += 1;
		}
		// parents
		else if (fieldType === PARENT_FIELD_TYPE && fetchParents) {
			fieldConfig[id].loadOptions = fetchParents;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.noParentLabel),
					isEmptyOption: true,
					...(isVisualRefreshEnabled() && { square: true }),
				},
			];
			fieldConfig[id].totalCount += 1;
		}
		// Goals
		else if (fieldType === GOALS_CF_TYPE && fetchGoals) {
			fieldConfig[id].loadOptions = fetchGoals;
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.noGoalLabel),
					isEmptyOption: true,
				},
			];
			fieldConfig[id].totalCount += 1;
		} else if (fetchTeams && fieldType === TEAMS_PLATFORM_CF_TYPE) {
			fieldConfig[id].loadOptions = fetchTeams;
			fieldConfig[id].defaultOptions = fieldValues?.options || [];
			fieldConfig[id].options = [
				...fieldConfig[id].options,
				{
					value: EMPTY_FILTER_VALUE,
					label: formatMessage(messages.noTeamLabel),
					isEmptyOption: true,
				},
			];
			if (fetchTeamsById) {
				resolvers[id] = createTeamValueGetter({ fetchTeamsById, options: fieldConfig[id].options });
			}
			fieldConfig[id].totalCount += 1;
		}
	});

	return { fieldConfig, resolvers };
};

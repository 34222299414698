/** @jsx jsx */
import React, { memo, type MouseEvent } from 'react';
import { jsx, css } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { AvatarLite } from '@atlassian/jira-business-avatar-lite/src/index.tsx';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants/src/index.tsx';
import { isOptimisticIssue } from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/IssueCreateProvider.tsx';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';
import {
	statusCategoryForId,
	StatusCategoryIds,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { CardDueDate } from '@atlassian/jira-platform-card/src/common/ui/due-date/index.tsx';
import { CardKey } from '@atlassian/jira-platform-card/src/common/ui/key/index.tsx';
import { CardLabels } from '@atlassian/jira-platform-card/src/common/ui/labels/index.tsx';
import { CardPriority } from '@atlassian/jira-platform-card/src/common/ui/priority/index.tsx';
import { CardSpinner } from '@atlassian/jira-platform-card/src/common/ui/spinner/index.tsx';
import { CardTick } from '@atlassian/jira-platform-card/src/common/ui/tick/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { CardContent_view$key } from '@atlassian/jira-relay/src/__generated__/CardContent_view.graphql';
import {
	LABEL_ID,
	STATUS_ID,
	DUE_DATE_ID,
	ISSUE_TYPE_ID,
	PRIORITY_ID,
	ASSIGNEE_ID,
	ISSUE_KEY_ID,
} from '../../../../../common/constants.tsx';
import type { BoardIssue } from '../../../../../common/types.tsx';
import { CardDetailFields } from './card-detail-fields/CardDetailFields.tsx';
import CardIssueType from './card-issue-type/index.tsx';
import Subtasks from './subtasks/index.tsx';

export type CardContentProps = {
	highlight?: string[];
	issue: BoardIssue;
	onIssueKeyClick: (event: MouseEvent<HTMLElement>) => void;
	viewFragment: CardContent_view$key;
};

export const CardContent = memo(
	({ highlight, issue, onIssueKeyClick, viewFragment }: CardContentProps) => {
		const view = useFragment(
			graphql`
				fragment CardContent_view on JiraBoardView {
					cardOptions(first: 100, enabledOnly: true) @required(action: THROW) {
						...CardDetailFields_cardOptions
						edges @required(action: THROW) {
							node @required(action: THROW) {
								... on JiraBoardViewFieldCardOption {
									field @required(action: THROW) {
										fieldId @required(action: THROW)
									}
								}
							}
						}
					}
					groupByConfig @required(action: THROW) {
						fieldId @required(action: THROW)
					}
				}
			`,
			viewFragment,
		);

		const cardOptions = view.cardOptions;

		const groupBy = view.groupByConfig.fieldId;
		const isNewOptimisticIssue = isOptimisticIssue(issue.id);

		const labels = issue.fields[LABEL_ID]?.value ?? undefined;
		const isDone = issue.fields[STATUS_ID].status.statusCategoryId === StatusCategoryIds.done;
		const isStandardIssue = issue.fields[ISSUE_TYPE_ID].issueType.hierarchyLevel === 0;

		const selectedFields = new Set(
			cardOptions.edges.map((edge) => edge?.node?.field && edge.node.field.fieldId),
		);

		const renderedLabels =
			labels && selectedFields.has('labels') ? (
				<div css={singleFieldRowStyles}>
					<CardLabels labels={labels} highlight={highlight} />
				</div>
			) : null;

		const dueDate = issue.fields[DUE_DATE_ID];
		const renderedDueDate =
			dueDate != null && selectedFields.has('duedate') ? (
				<div css={singleFieldRowStyles}>
					<CardDueDate
						dueDate={dueDate.value}
						isCompleted={isDone}
						dateFormat={isVisualRefreshEnabled() ? 'dd MMM yyyy' : undefined}
					/>
				</div>
			) : null;

		const renderedIssueType = selectedFields.has('issuetype') ? (
			<CardIssueType issueType={issue.fields[ISSUE_TYPE_ID]} />
		) : null;

		const priority = issue.fields[PRIORITY_ID];
		const renderedPriority =
			priority && selectedFields.has('priority') ? (
				<CardPriority uri={priority.priority.iconUrl} name={priority.priority.name} />
			) : null;

		const assignee = issue.fields[ASSIGNEE_ID];
		const renderedAssignee =
			assignee?.user && selectedFields.has('assignee') ? (
				<AvatarLite
					highlight={highlight}
					avatarUrl={assignee.user.avatarURL ?? undefined}
					name={assignee.user.name}
				/>
			) : null;

		const renderedKey = selectedFields.has('issuekey') && (
			<div css={issueKeyWrapperStyles}>
				<CardKey
					highlight={highlight}
					onClick={onIssueKeyClick}
					text={issue.fields[ISSUE_KEY_ID].value}
				/>
			</div>
		);

		const { status } = issue.fields[STATUS_ID];
		const renderedStatus =
			groupBy !== GROUP_BY_STATUS && status.statusCategoryId && status.name ? (
				<div css={singleFieldRowStyles}>
					<StatusLozenge
						name={status.name}
						category={statusCategoryForId(status.statusCategoryId)}
					/>
				</div>
			) : null;

		const showMultiFieldRow =
			renderedIssueType || renderedKey || isDone || renderedPriority || renderedAssignee;

		return (
			<>
				<div css={[contentWrapperStyles, isVisualRefreshEnabled() && contentWrapperRefreshStyles]}>
					{renderedStatus}
					{renderedLabels}
					{renderedDueDate}
					{showMultiFieldRow && (
						<div css={multiFieldRowStyles}>
							{renderedIssueType}
							{renderedKey}
							{isDone && <CardTick />}
							{renderedPriority}
							{renderedAssignee}
						</div>
					)}
					{selectedFields.has('subtasks') && isStandardIssue && <Subtasks issue={issue} />}
					<CardDetailFields issue={issue} cardOptionsFragment={cardOptions} />
				</div>
				{isNewOptimisticIssue && (
					<div css={spinnerContainerStyles}>
						<CardSpinner />
					</div>
				)}
			</>
		);
	},
);

const contentWrapperStyles = css({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100'),
	paddingTop: 0,
	paddingRight: token('space.150'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.150'),
	'&:empty': {
		display: 'none',
	},
});

const contentWrapperRefreshStyles = css({
	paddingBottom: token('space.100'),
});

const spinnerContainerStyles = css({
	position: 'absolute',
	top: token('space.200'),
	right: token('space.200'),
});

const multiFieldRowStyles = css({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050'),
	// prevent layout shift when fields appear
	minHeight: '20px',
});

const issueKeyWrapperStyles = css({
	display: 'flex',
	flex: 1,
	minWidth: 0,
});

const singleFieldRowStyles = css({
	display: 'flex',
	alignItems: 'center',
});

/** @jsx jsx */
import React, {
	useRef,
	memo,
	useCallback,
	type MouseEvent,
	type KeyboardEvent,
	useEffect,
	useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { styled, cssMap, css, jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { useNodeSelections } from '@atlassian/jira-business-collaboration/src/controllers/collab-store/index.tsx';
import { SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants/src/index.tsx';
import {
	BADGE_INNER_POSITION as INNER,
	BADGE_OUTER_POSITION as OUTER,
} from '@atlassian/jira-business-collaboration/src/ui/node-selection/constants.tsx';
import { openViewFromEmbed } from '@atlassian/jira-business-common/src/common/utils/embeds/index.tsx';
import {
	isOptimisticIssue,
	useIsHighlighted,
} from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/IssueCreateProvider.tsx';
import { isSafari } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { CardSummary } from '@atlassian/jira-platform-card/src/common/ui/summary/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	BoardCard_column$key,
	BoardCard_column$data,
} from '@atlassian/jira-relay/src/__generated__/BoardCard_column.graphql';
import type { BoardCard_query$key } from '@atlassian/jira-relay/src/__generated__/BoardCard_query.graphql';
import type { BoardCard_CoverWrapper_query$key } from '@atlassian/jira-relay/src/__generated__/BoardCard_CoverWrapper_query.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { BoardCard_view$key } from '@atlassian/jira-relay/src/__generated__/BoardCard_view.graphql';
import type { BoardCard_CoverWrapper_view$key } from '@atlassian/jira-relay/src/__generated__/BoardCard_CoverWrapper_view.graphql';
import type { BoardCard_project$key } from '@atlassian/jira-relay/src/__generated__/BoardCard_project.graphql';
import { ISSUE_KEY_ID, SUMMARY_ID, CARD_COVER } from '../../../../common/constants.tsx';
import type { BoardIssue, BoardIssueCoverMedia, Group } from '../../../../common/types.tsx';
import { CardCoverEditingContainer } from '../../../../controllers/card-cover-editing-state/index.tsx';
import {
	useCardDragDrop,
	useHighlightedIssue,
} from '../../../../controllers/drag-and-drop/card-drag-drop-context/index.tsx';
import {
	useDraggableCard,
	type OnCardDragChange,
} from '../../../../controllers/drag-and-drop/use-draggable-card/index.tsx';
import { useJWMBoardFeatures } from '../../../../controllers/features-context/index.tsx';
import { useViewIssue } from '../../../../controllers/view-issue/index.tsx';
import InlineSiblingCreate from '../inline-create/BoardInlineCreate.tsx';
import { CardContent } from './card-content/CardContent.tsx';
import { CardCover } from './card-cover/index.tsx';
import { CardBadge } from './card-badge/index.tsx';
import { CardFooter } from './card-footer/CardFooter.tsx';
import CardActions, {
	CARD_ACTIONS_DISPLAY_VARIABLE,
	CARD_ACTIONS_OPACITY_VARIABLE,
} from './card-actions/BoardCardActions.tsx';

const isSafariBrowser = isSafari();

type CardProps = {
	queryFragment: BoardCard_query$key;
	projectFragment: BoardCard_project$key;
	viewFragment: BoardCard_view$key;
	columnFragment: BoardCard_column$key;
	issue: BoardIssue;
	draggableIndex: number;
	dragAndDropColumnData: Group;
	canCreateIssues: boolean;
	highlight?: string[];
	isSiblingCreateFormOpen: boolean;
	toggleSiblingCreateForm: (issueId: number | null) => void;
	onCardDragChange: OnCardDragChange;
};

const useDropHighlight = (issueId: number) => {
	const { highlightedIssueId, resetHighlightedIssue } = useHighlightedIssue();

	const isDropHighlighted = highlightedIssueId === issueId;

	useEffect(() => {
		if (isDropHighlighted) {
			/*
			 * We need to reset the highlighted issue after a short delay to ensure the card has enough time to move
			 * to the correct position after a successful drop.
			 */
			const timeoutId = setTimeout(() => {
				resetHighlightedIssue();
			}, 100);

			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [isDropHighlighted, resetHighlightedIssue]);

	return isDropHighlighted;
};

export const BoardCard = memo<CardProps>(
	({
		columnFragment,
		projectFragment,
		queryFragment,
		viewFragment,
		canCreateIssues,
		draggableIndex,
		dragAndDropColumnData,
		highlight,
		isSiblingCreateFormOpen,
		issue,
		toggleSiblingCreateForm,
		onCardDragChange,
	}) => {
		const issueKey = issue.fields[ISSUE_KEY_ID].value;
		const { issueViewInteraction } = useJWMBoardFeatures();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const shouldUseEmbedIssueClick = issueViewInteraction === 'openNewTab';
		const getIsHighlighted = useIsHighlighted();
		const isHighlighted = getIsHighlighted(issue.id);
		const isDropHighlighted = useDropHighlight(issue.id);

		const data = useFragment(
			graphql`
				fragment BoardCard_query on Query {
					...BoardCard_CoverWrapper_query
					...BoardInlineCreate_query
				}
			`,
			queryFragment,
		);

		const project = useFragment(
			graphql`
				fragment BoardCard_project on JiraProject {
					...BoardCardActions_project
				}
			`,
			projectFragment,
		);

		const view = useFragment(
			graphql`
				fragment BoardCard_view on JiraBoardView {
					...BoardCard_CoverWrapper_view
					...CardContent_view
					...BoardCardActions_view
					id @required(action: THROW)
					cardOptions(first: 100, enabledOnly: true) @required(action: THROW) {
						...CardFooter_cardOptions
					}
				}
			`,
			viewFragment,
		);

		const column: BoardCard_column$data = useFragment(
			graphql`
				fragment BoardCard_column on JiraBoardViewColumn {
					...BoardInlineCreate_column
					...BoardCardActions_column
				}
			`,
			columnFragment,
		);

		const ref = useRef<HTMLDivElement | null>(null);

		const nodeId = `${view.id}/node/${issue.id}`;
		const cardSelections = useNodeSelections({ nodeId });

		const { draggedIssue } = useCardDragDrop();
		const { dragState } = useDraggableCard({
			ref,
			issue,
			cardIndex: draggableIndex,
			column: dragAndDropColumnData,
			onCardDragChange,
		});
		const isDragging = draggedIssue?.id === issue.id;
		const isDraggingAllowed = !isOptimisticIssue(issue.id);
		const applyHighlightingStyles = isHighlighted || isDropHighlighted;

		const onEmbedIssueOpen = useCallback(() => {
			fireUIAnalytics(createAnalyticsEvent({}), 'issue clicked', 'boardEmbedIssue');
			openViewFromEmbed({ key: SELECTED_ISSUE_PARAM, value: issueKey });
		}, [createAnalyticsEvent, issueKey]);

		const onIssueClick = useViewIssue(nodeId);
		const clickHandler = useCallback(
			(e: MouseEvent<HTMLElement>) => {
				onIssueClick(issue, e.ctrlKey || e.metaKey);
				fireUIAnalytics(createAnalyticsEvent({}), 'issue clicked', 'boardIssue');
			},
			[onIssueClick, issue, createAnalyticsEvent],
		);

		const issueKeyClickHandler = useCallback(() => {
			onIssueClick(issue, false);
		}, [onIssueClick, issue]);

		const handleKeyDown = useCallback(
			(event: KeyboardEvent<HTMLDivElement>) => {
				if (event.key === 'Enter' && event.target === event.currentTarget) {
					shouldUseEmbedIssueClick ? onEmbedIssueOpen() : onIssueClick(issue, false);
				}
			},
			[onIssueClick, issue, onEmbedIssueOpen, shouldUseEmbedIssueClick],
		);

		const cardContent = (
			<>
				{isVisualRefreshEnabled() && fg('visual-refresh_drop_6') ? (
					<Box xcss={cardSummaryWrapperStyles}>
						<CardSummary
							text={issue.fields[SUMMARY_ID].value}
							shouldMenuRender
							highlight={highlight}
						/>
					</Box>
				) : (
					<div css={cardSummaryWrapperOld}>
						<CardSummary
							text={issue.fields[SUMMARY_ID].value}
							shouldMenuRender
							highlight={highlight}
						/>
					</div>
				)}

				<CardContent
					highlight={highlight}
					issue={issue}
					onIssueKeyClick={shouldUseEmbedIssueClick ? onEmbedIssueOpen : issueKeyClickHandler}
					viewFragment={view}
				/>
			</>
		);

		const cardContainer = (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				css={cardContentContainerStyles}
				onClick={shouldUseEmbedIssueClick ? onEmbedIssueOpen : clickHandler}
				onKeyDown={handleKeyDown}
				data-testid="work-management-board.ui.board.column.card.container"
			>
				{cardContent}
				<CardFooter cardOptionsFragment={view.cardOptions} issue={issue} isPlaceholder />
			</div>
		);

		const inlineSiblingCreateProps = useMemo(
			() => ({
				isTopMost: draggableIndex === 0,
				issue,
				isSiblingCreateFormOpen,
				toggleSiblingCreateForm,
			}),
			[draggableIndex, isSiblingCreateFormOpen, issue, toggleSiblingCreateForm],
		);

		return (
			<>
				{canCreateIssues && (
					<InlineSiblingCreate
						queryFragment={data}
						columnFragment={column}
						inlineSiblingCreateProps={inlineSiblingCreateProps}
						isInlineSiblingCreate
					/>
				)}

				<div
					css={[cardContainerStyles.root, isDragging && cardContainerStyles.dragging]}
					ref={ref}
					data-testid="work-management-board.ui.board.column.card"
				>
					<CardCoverEditingContainer>
						{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
						<div
							css={cardContentContainerStyles}
							onKeyDown={handleKeyDown}
							onClick={shouldUseEmbedIssueClick ? onEmbedIssueOpen : clickHandler}
						>
							<BoardCardCoverWrapper
								queryFragment={data}
								viewFragment={view}
								coverMedia={issue.coverMedia}
							/>
						</div>
						<div css={relativeMainContentContainerStyles}>
							<div
								css={[
									highlightContainerStyles.root,
									isDraggingAllowed && highlightContainerStyles.dragging,
									applyHighlightingStyles && highlightContainerStyles.highlighted,
								]}
							>
								{cardSelections ? (
									<CardBadge
										selections={cardSelections}
										position={draggableIndex === 0 ? INNER : OUTER}
									>
										{cardContainer}
									</CardBadge>
								) : (
									cardContainer
								)}
							</div>

							<CardFooter cardOptionsFragment={view.cardOptions} issue={issue} />

							{isDraggingAllowed && (
								<CardActions
									issue={issue}
									columnFragment={column}
									projectFragment={project}
									viewFragment={view}
								/>
							)}
						</div>
					</CardCoverEditingContainer>
				</div>

				{dragState.type === 'preview'
					? createPortal(
							<CardDragPreviewWrapper isTilted={!isSafariBrowser}>
								{cardContent}
							</CardDragPreviewWrapper>,
							dragState.container,
						)
					: null}
			</>
		);
	},
);

type CardCoverWrapperProps = {
	queryFragment: BoardCard_CoverWrapper_query$key;
	viewFragment: BoardCard_CoverWrapper_view$key;
	coverMedia: BoardIssueCoverMedia | null;
};

const BoardCardCoverWrapper = memo<CardCoverWrapperProps>(
	({ queryFragment, viewFragment, coverMedia }) => {
		const data = useFragment(
			graphql`
				fragment BoardCard_CoverWrapper_query on Query {
					...cardCover_workManagementBoard_queryFragment
				}
			`,
			queryFragment,
		);

		const view = useFragment(
			graphql`
				fragment BoardCard_CoverWrapper_view on JiraBoardView {
					cardOptions(first: 100, enabledOnly: true) @required(action: THROW) {
						edges @required(action: THROW) {
							node @required(action: THROW) {
								... on JiraBoardViewFieldCardOption {
									field @required(action: THROW) {
										fieldId @required(action: THROW)
									}
								}
							}
						}
					}
				}
			`,
			viewFragment,
		);

		const showCardCovers = view.cardOptions.edges.some(
			(edge) => edge?.node?.field?.fieldId === CARD_COVER,
		);

		return showCardCovers && <CardCover coverMedia={coverMedia} queryFragment={data} />;
	},
);

export default BoardCard;

const MAX_CARD_WIDTH = 270;
const ACTION_MENU_BUTTON_SIZE = 32;

const cardContainerStyles = cssMap({
	root: {
		maxWidth: MAX_CARD_WIDTH,
		userSelect: 'none',
		boxSizing: 'border-box',
		position: 'relative',
		borderRadius: token('border.radius'),
		boxShadow: token('elevation.shadow.raised'),
		backgroundColor: token('elevation.surface.raised'),
		color: token('color.text'),
		opacity: 1,
		transition: 'opacity 0.2s ease',
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: token('elevation.surface.hovered'),
		},
		'&:hover, &:focus-within': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			[CARD_ACTIONS_DISPLAY_VARIABLE]: 'flex',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			[CARD_ACTIONS_OPACITY_VARIABLE]: 1,
		},
	},
	dragging: {
		opacity: token('opacity.disabled'),
		transition: 'opacity 0.2s ease',
		'&:hover': {
			cursor: 'grabbing',
		},
	},
});

const relativeMainContentContainerStyles = css({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardDragPreviewWrapper = styled.div<{ isTilted: boolean }>({
	width: MAX_CARD_WIDTH,
	boxSizing: 'border-box',
	position: 'relative',
	borderRadius: token('border.radius'),
	backgroundColor: token('elevation.surface.raised'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	rotate: ({ isTilted }) => (isTilted ? '4deg' : '0deg'),
});

const cardContentContainerStyles = css({
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
});

const cardSummaryWrapperOld = css({
	paddingTop: token('space.150'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.150'),
	display: 'flex',
	minHeight: `${ACTION_MENU_BUTTON_SIZE}px`,
});

const cardSummaryWrapperStyles = xcss({
	display: 'flex',
	paddingInline: 'space.150',
	paddingBlock: 'space.100',
	// min height is set to match action menu button height + padding
	minHeight: '48px',
});

const highlightContainerStyles = cssMap({
	root: {
		backgroundColor: 'transparent',
		cursor: 'not-allowed',
		transition: 'background-color 1s ease-out',
	},
	dragging: {
		cursor: 'inherit',
	},
	highlighted: {
		backgroundColor: token('color.background.accent.blue.subtler'),
		transition: 'none',
	},
});

/* eslint-disable eslint-comments/no-restricted-disable */
/* eslint-disable @atlassian/react-entrypoint/no-imports-in-entrypoint-file */
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import BoardMoreMenuContentQuery from '@atlassian/jira-relay/src/__generated__/BoardMoreMenuContentQuery.graphql';
import { expVal } from '@atlassian/jira-feature-experiments';
import { getUrlSettingsInput } from '../../../common/url-settings-input.tsx';

export const moreMenuContentEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-business-board-more-menu-content-entrypoint" */ './BoardMoreMenuContent.tsx'
			),
	),
	getPreloadProps: ({ projectAri, viewId }: { projectAri: string; viewId: string }) => ({
		queries: {
			mainQuery: {
				parameters: BoardMoreMenuContentQuery,
				variables: {
					projectAri,
					viewInput: {
						jiraBoardViewQueryInput: {
							viewId,
						},
						settings: getUrlSettingsInput(),
					},
					withMergedWorkflows: expVal('merge_board_workflows', 'enabled', false),
				},
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
			},
		},
	}),
});

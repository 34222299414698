import get from 'lodash/get';
import isSameWeek from 'date-fns/isSameWeek';
import type {
	Filters as FiltersType,
	SelectValue,
	SelectOption,
	Value,
} from '@atlassian/jira-business-filters/src/common/types.tsx';
import { isOptimisticIssue } from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/IssueCreateProvider.tsx';
import {
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	ISSUE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Field } from '@atlassian/jira-router-resources-business-fields/src/index.tsx';
import {
	ASSIGNEE_ID,
	CATEGORY_ID,
	COMPONENT_ID,
	DUE_DATE_ID,
	EMPTY_FILTER_VALUE,
	ISSUE_KEY_ID,
	PARENT_ID,
	SUMMARY_ID,
} from '../../common/constants.tsx';
import type {
	BoardIssue,
	BoardIssueCategoryField,
	BoardIssueComponentField,
	BoardIssueIssueTypeField,
	BoardIssueLabelField,
	BoardIssuePriorityField,
	BoardIssueSelectField,
	BoardIssueStatusField,
	BoardIssueUserField,
	BoardIssueMultiUserField,
	BoardIssueFields,
} from '../../common/types.tsx';

export const isAssignedToMe = (issues: BoardIssue[], atlassianAccountId: string): BoardIssue[] =>
	issues.filter(
		({ id, fields }) =>
			isOptimisticIssue(id) || fields[ASSIGNEE_ID]?.user.accountId === atlassianAccountId,
	);

export const isDueThisWeek = (issues: BoardIssue[]): BoardIssue[] =>
	issues.filter((issue) => {
		if (isOptimisticIssue(issue.id)) {
			return true;
		}
		if (!issue.fields[DUE_DATE_ID]) return false;
		const dueDate = new Date(issue.fields[DUE_DATE_ID].value);
		const today = new Date();
		return isSameWeek(dueDate, today);
	});

export const filterIssuesByText: (arg1: BoardIssue[], arg2: string | undefined) => BoardIssue[] = (
	issues,
	searchText,
) => {
	if (searchText != null && searchText.trim() !== '') {
		const trimmedSearchTextInLowerCase = searchText.trim().toLowerCase();
		return issues.filter(
			({ id, fields }) =>
				isOptimisticIssue(id) ||
				fields[SUMMARY_ID].value.toLowerCase().includes(trimmedSearchTextInLowerCase) ||
				fields[ASSIGNEE_ID]?.user.name?.toLowerCase().includes(trimmedSearchTextInLowerCase) ||
				fields[ISSUE_KEY_ID].value.toLowerCase().includes(trimmedSearchTextInLowerCase),
		);
	}
	return issues;
};

export const jqlTermToFieldId = (
	jqlTerm: string,
	fields: Field[],
	categoryFieldId: string | null,
): string | null => {
	const field = fields.find((f) => f.jqlTerm === jqlTerm);
	if (field?.type === CATEGORY_TYPE) {
		return categoryFieldId;
	}
	if (field?.type === COMPONENTS_TYPE) {
		return COMPONENTS_TYPE;
	}
	if (field?.type === ISSUE_TYPE) {
		return ISSUE_TYPE;
	}

	return jqlTerm;
};

const jqlTermToIssueFieldKey = (jqlTerm: string, fields: Field[] | null) => {
	const field = fields?.find((f) => f.jqlTerm === jqlTerm);
	if (field?.type === CATEGORY_TYPE) {
		return CATEGORY_ID;
	}
	if (field?.type === COMPONENTS_TYPE) {
		return COMPONENT_ID;
	}

	return field?.type ?? jqlTerm;
};

const getFilterValues = (
	filterValueForExtendedField: SelectValue<SelectOption>,
	valueProperty: string,
): Set<string | null> =>
	new Set(filterValueForExtendedField.map((filterValue) => get(filterValue, valueProperty, null)));

const isSelectOption = (filterValue: Value): filterValue is SelectValue<SelectOption> =>
	Array.isArray(filterValue) && filterValue.length > 0 && 'value' in filterValue[0];

export type ExpandedFilter = {
	jqlTerm: string;
	fieldKey: string;
	filterValues: Set<string | null>;
	hasEmptyFilter: boolean;
};

const FILTER_VALUE_PROPERTY: { [jqlTerm: string]: string } = {
	type: 'id',
	issuetype: 'id',
	priority: 'label',
	status: 'label',
};

export const getExpandedFilters = (
	filters: FiltersType,
	fields: Field[] | null,
): ExpandedFilter[] =>
	Object.entries(filters).map(([jqlTerm, filterValue]) => {
		const filterValues = isSelectOption(filterValue)
			? getFilterValues(filterValue, FILTER_VALUE_PROPERTY[jqlTerm] ?? 'value')
			: new Set<string>();

		return {
			jqlTerm,
			fieldKey: jqlTermToIssueFieldKey(jqlTerm, fields),
			filterValues,
			hasEmptyFilter: filterValues.has(EMPTY_FILTER_VALUE),
		};
	});

export const filterByCategory = (filter: ExpandedFilter, field: BoardIssueCategoryField) => {
	const category = field.category?.name;
	return filter.filterValues.has(category ?? '') || (filter.hasEmptyFilter && !category);
};

export const filterByComponents = (filter: ExpandedFilter, field: BoardIssueComponentField) => {
	const components = field.value;
	return (
		Array.isArray(components) &&
		(components.some((component) => filter.filterValues.has(component)) ||
			(filter.hasEmptyFilter && !components.length))
	);
};

export const filterByIssueType = (filter: ExpandedFilter, field: BoardIssueIssueTypeField) => {
	const issueTypeId = field.issueType.id;
	return filter.filterValues.has(issueTypeId);
};

export const filterByLabels = (filter: ExpandedFilter, field: BoardIssueLabelField) => {
	const labels = field.value;
	return (
		Array.isArray(labels) &&
		(labels.some((label) => filter.filterValues.has(label)) ||
			(filter.hasEmptyFilter && !labels.length))
	);
};

export const filterByPriority = (filter: ExpandedFilter, field: BoardIssuePriorityField) => {
	const priorityName = field.priority.name;
	return filter.filterValues.has(priorityName);
};

export const filterBySelect = (filter: ExpandedFilter, field: BoardIssueSelectField) => {
	const selectedValues = field.value;
	return (
		Array.isArray(selectedValues) &&
		(selectedValues.some((selectedValue) => filter.filterValues.has(selectedValue)) ||
			(filter.hasEmptyFilter && !selectedValues.length))
	);
};

export const filterByStatus = (filter: ExpandedFilter, field: BoardIssueStatusField) => {
	const statusName = field.status.name;
	return filter.filterValues.has(statusName);
};

export const filterByUser = (filter: ExpandedFilter, field: BoardIssueUserField) => {
	const user = field.user.accountId;
	return filter.filterValues.has(user);
};

export const filterByMultiUser = (filter: ExpandedFilter, field: BoardIssueMultiUserField) => {
	const users = field.users.map((user) => user.accountId);
	return (
		Array.isArray(users) &&
		(users.some((user) => filter.filterValues.has(user)) ||
			(filter.hasEmptyFilter && !users.length))
	);
};

/**
 * This logic keeps the parent issues themself and all their children in the result
 * It looks into two fields: parent and issue key
 */
export const filterByParent = (filter: ExpandedFilter, issueFields: BoardIssueFields) => {
	const issueKey = issueFields[ISSUE_KEY_ID].value;
	const parentKey = issueFields[PARENT_ID]?.parent?.key ?? null;
	return filter.filterValues.has(parentKey) || filter.filterValues.has(issueKey);
};

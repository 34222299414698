/**
 * @generated SignedSource<<4a6ac57956b09226701b3edce684fbb6>>
 * @relayHash dad8ec7c7ecfba97b8870580d58745de
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ae4d4489739b7c0ad9e9e6896e9bfe73969e1f84c285494d6a091289976dd388

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSetBoardViewColumnsOrderInput = {
  columnIds: ReadonlyArray<string>;
  settings?: JiraBoardViewSettings | null | undefined;
  viewId: string;
};
export type JiraBoardViewSettings = {
  filterJql?: string | null | undefined;
  groupBy?: string | null | undefined;
};
export type BoardContentColumnsOrderMutation$variables = {
  input: JiraSetBoardViewColumnsOrderInput;
};
export type BoardContentColumnsOrderMutation$data = {
  readonly jira_setBoardViewColumnsOrder: {
    readonly boardView: {
      readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_view">;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly extensions: {
        readonly errorType: string | null | undefined;
        readonly statusCode: number | null | undefined;
      } | null | undefined;
      readonly message: string | null | undefined;
    }> | null | undefined;
    readonly success: boolean;
  } | null | undefined;
};
export type BoardContentColumnsOrderMutation = {
  response: BoardContentColumnsOrderMutation$data;
  variables: BoardContentColumnsOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "message"
},
v4 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v5 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v6 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v7 = [
  (v6/*: any*/),
  {
    "kind": "ScalarField",
    "name": "fieldName"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "BoardContentColumnsOrderMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraSetBoardViewColumnsOrderPayload",
        "kind": "LinkedField",
        "name": "jira_setBoardViewColumnsOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JiraBoardView",
            "kind": "LinkedField",
            "name": "boardView",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "BusinessBoard_view"
              }
            ]
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardContentColumnsOrderMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "JiraSetBoardViewColumnsOrderPayload",
        "kind": "LinkedField",
        "name": "jira_setBoardViewColumnsOrder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "JiraBoardView",
            "kind": "LinkedField",
            "name": "boardView",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraViewFilterConfig",
                "kind": "LinkedField",
                "name": "filterConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "jql"
                  }
                ]
              },
              {
                "concreteType": "JiraViewGroupByConfig",
                "kind": "LinkedField",
                "name": "groupByConfig",
                "plural": false,
                "selections": (v7/*: any*/)
              },
              (v8/*: any*/),
              {
                "kind": "ScalarField",
                "name": "selectedWorkflowId"
              },
              {
                "kind": "ScalarField",
                "name": "isViewConfigModified"
              },
              {
                "kind": "ScalarField",
                "name": "canPublishViewConfig"
              },
              {
                "concreteType": "JiraViewGroupByConfig",
                "kind": "LinkedField",
                "name": "groupByOptions",
                "plural": true,
                "selections": (v7/*: any*/)
              },
              {
                "concreteType": "JiraBoardViewColumnConnection",
                "kind": "LinkedField",
                "name": "columns",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraBoardViewColumnEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v8/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "collapsed"
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isJiraBoardViewColumn"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "statuses",
                                "plural": true,
                                "selections": [
                                  (v10/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "statusId"
                                  },
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "statusCategoryId"
                                      },
                                      (v8/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "type": "JiraBoardViewStatusColumn"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "priorityId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "iconUrl"
                                  },
                                  (v8/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraBoardViewPriorityColumn"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "accountId"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "picture"
                                  },
                                  (v8/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraBoardViewAssigneeColumn"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraOption",
                                "kind": "LinkedField",
                                "name": "category",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "value"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "optionId"
                                  },
                                  {
                                    "concreteType": "JiraColor",
                                    "kind": "LinkedField",
                                    "name": "color",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "colorKey"
                                      },
                                      (v8/*: any*/)
                                    ]
                                  },
                                  (v8/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraBoardViewCategoryColumn"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "totalCount"
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "handle": "connection",
                "key": "business_board_columns",
                "kind": "LinkedHandle",
                "name": "columns"
              },
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "enabledOnly",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  }
                ],
                "concreteType": "JiraBoardViewCardOptionConnection",
                "kind": "LinkedField",
                "name": "cardOptions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraBoardViewCardOptionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraField",
                                "kind": "LinkedField",
                                "name": "field",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v8/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraBoardViewFieldCardOption"
                          },
                          (v8/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "cardOptions(enabledOnly:true,first:100)"
              }
            ]
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "type": "BulkMutationErrorExtension"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ae4d4489739b7c0ad9e9e6896e9bfe73969e1f84c285494d6a091289976dd388",
    "metadata": {},
    "name": "BoardContentColumnsOrderMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1ca33a967169dbcedaa0e675323367ba";

export default node;

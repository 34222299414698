/** @jsx jsx */
import React, { memo, useCallback, useState, useMemo } from 'react';
import { css, jsx, styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { cssMap } from '@atlaskit/css';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIconOld from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { BoardCardActions_column$key } from '@atlassian/jira-relay/src/__generated__/BoardCardActions_column.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ModalContextProvider } from '@atlassian/jira-modal-context-provider/src/ModalContextProvider.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import type { BoardCardActions_view$key } from '@atlassian/jira-relay/src/__generated__/BoardCardActions_view.graphql';
import type { BoardCardActions_project$key } from '@atlassian/jira-relay/src/__generated__/BoardCardActions_project.graphql';
import type { BoardIssue } from '../../../../../common/types.tsx';
import { cardActionsMenuContentEntryPoint } from './card-actions-menu/entrypoint.tsx';
import messages from './messages.tsx';

/**
 * @deprecated delete CARD_ACTIONS_DISPLAY_VARIABLE when cleaning up isVisualRefreshEnabled
 */
export const CARD_ACTIONS_DISPLAY_VARIABLE = '--card-actions-display';
export const CARD_ACTIONS_OPACITY_VARIABLE = '--card-actions-opacity';

export type Props = {
	issue: BoardIssue;
	columnFragment: BoardCardActions_column$key;
	projectFragment: BoardCardActions_project$key;
	viewFragment: BoardCardActions_view$key;
};

const styles = cssMap({
	fallbackMenu: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '220px',
		paddingTop: token('space.400'),
		paddingRight: token('space.400'),
		paddingBottom: token('space.400'),
		paddingLeft: token('space.400'),
	},
});

const BoardCardActions = ({ issue, columnFragment, projectFragment, viewFragment }: Props) => {
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();

	const view = useFragment(
		graphql`
			fragment BoardCardActions_view on JiraBoardView {
				id @required(action: THROW)
			}
		`,
		viewFragment,
	);

	const project = useFragment(
		graphql`
			fragment BoardCardActions_project on JiraProject {
				id @required(action: THROW)
			}
		`,
		projectFragment,
	);

	const column = useFragment(
		graphql`
			fragment BoardCardActions_column on JiraBoardViewColumn {
				__typename
				... on JiraBoardViewStatusColumn {
					statuses @required(action: THROW) {
						name @required(action: THROW)
					}
				}
				... on JiraBoardViewPriorityColumn {
					priority @required(action: THROW) {
						name @required(action: THROW)
					}
				}
				... on JiraBoardViewAssigneeColumn {
					user {
						name @required(action: THROW)
					}
				}
				... on JiraBoardViewCategoryColumn {
					category {
						value @required(action: THROW)
					}
				}
			}
		`,
		columnFragment,
	);

	let columnName;
	switch (column.__typename) {
		case 'JiraBoardViewAssigneeColumn':
			columnName = column.user?.name ?? formatMessage(messages.noAssignee);
			break;
		case 'JiraBoardViewPriorityColumn':
			columnName = column.priority.name;
			break;
		case 'JiraBoardViewCategoryColumn':
			columnName = column.category?.value ?? formatMessage(messages.noCategory);
			break;
		case 'JiraBoardViewStatusColumn':
			if (!column.statuses?.[0]) {
				throw new Error('Missing status column fields');
			}
			columnName = column.statuses[0].name;
			break;
		default:
			throw new Error(`Unhandled column type: ${column.__typename}`);
	}

	const [isOpen, setIsOpen] = useState(false);
	const openMenu = useCallback(() => setIsOpen(true), []);
	const closeMenu = useCallback(() => setIsOpen(false), []);
	const toggleMenu = useCallback(() => setIsOpen((open) => !open), []);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		cardActionsMenuContentEntryPoint,
		useMemo(
			() => ({ cloudId, projectAri: project.id, viewId: view.id }),
			[cloudId, project.id, view.id],
		),
	);
	const menuContentTrigger = useEntryPointButtonTrigger(entryPointActions, !isOpen);

	const popupContent = useCallback(
		({ update }: { update: () => void }) => (
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="business-board-card-actions-menu"
				packageName="@atlassian/jira-work-management-board"
				runtimeProps={{
					issue,
					onContentResize: update,
					onClosePopup: closeMenu,
					onMenuRendered: update,
					onOpenPopup: openMenu,
				}}
				fallback={
					<Box xcss={styles.fallbackMenu}>
						<Spinner />
					</Box>
				}
			/>
		),
		[closeMenu, entryPointReferenceSubject, issue, openMenu],
	);

	const trigger = useCallback(
		(triggerProps: TriggerProps) => {
			const label = formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.cardActionsWithIssueNameIssueTermRefresh
					: messages.cardActionsWithIssueName,
				{
					columnName,
					issueKey: issue.fields.issuekey.value,
				},
			);
			return isVisualRefreshEnabled() ? (
				<Box xcss={[IconButtonStyles, isOpen && IconButtonMenuOpenStyles]}>
					<IconButton
						{...triggerProps}
						ref={(element: HTMLButtonElement) => {
							menuContentTrigger(element);
							if (typeof triggerProps.ref === 'function') {
								triggerProps.ref(element);
							}
						}}
						icon={ShowMoreHorizontalIcon}
						label={label}
						appearance="subtle"
						onClick={toggleMenu}
						isSelected={isOpen}
						isTooltipDisabled={false}
						tooltip={{
							content: formatMessage(messages.moreActionsTooltip),
							hideTooltipOnClick: true,
						}}
						testId="work-management-board.ui.board.column.card.card-actions.trigger"
					/>
				</Box>
			) : (
				<div css={triggerButtonWrapperStyles}>
					<StyledButton
						{...triggerProps}
						ref={(element: HTMLButtonElement) => {
							menuContentTrigger(element);
							if (typeof triggerProps.ref === 'function') {
								triggerProps.ref(element);
							}
						}}
						iconBefore={<ShowMoreHorizontalIconOld label={label} color={token('color.icon')} />}
						onClick={toggleMenu}
						testId="work-management-board.ui.board.column.card.card-actions.trigger"
					/>
				</div>
			);
		},
		[
			formatMessage,
			columnName,
			issue.fields.issuekey.value,
			isOpen,
			toggleMenu,
			menuContentTrigger,
		],
	);

	return (
		<ModalContextProvider>
			<Popup
				isOpen={isOpen}
				onClose={closeMenu}
				content={popupContent}
				placement="right-start"
				messageId="work-management-board.ui.board.column.card.card-actions.popup"
				messageType="transactional"
				trigger={trigger}
			/>
		</ModalContextProvider>
	);
};

export default memo(BoardCardActions);

const triggerButtonWrapperStyles = css({
	position: 'absolute',
	top: token('space.100'),
	right: token('space.100'),
});

// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const StyledButton = styled(Button)({
	display: `var(${CARD_ACTIONS_DISPLAY_VARIABLE}, none)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:focus, &[aria-expanded="true"]': {
		display: 'flex',
	},
});

const IconButtonStyles = xcss({
	position: 'absolute',
	top: token('space.100'),
	right: token('space.100'),
	opacity: `var(${CARD_ACTIONS_OPACITY_VARIABLE}, 0)`,
});

const IconButtonMenuOpenStyles = xcss({
	opacity: 1,
});

/**
 * @generated SignedSource<<9e694dcf110cb626413fdae934ff9437>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardContent_query$data = {
  readonly jira_categoryField?: {
    readonly displayName: string;
    readonly fieldId: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_Inner_query">;
  readonly " $fragmentType": "BoardContent_query";
};
export type BoardContent_query$key = {
  readonly " $data"?: BoardContent_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "BoardContent_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardContent_Inner_query"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraJqlField",
          "kind": "LinkedField",
          "name": "jira_categoryField",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "fieldId"
              },
              "action": "THROW",
              "path": "jira_categoryField.fieldId"
            },
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "displayName"
              },
              "action": "THROW",
              "path": "jira_categoryField.displayName"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "7bdb0d0ef73260f0af39183364932bd9";

export default node;

/**
 * @generated SignedSource<<5862059e5c18515f5ae7b5f389edc942>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BusinessBoard_withWorkflow_query$data = {
  readonly jira_categoryField?: {
    readonly fieldId: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"BoardContent_query" | "BoardFieldIdsProvider_query" | "IssueCreateProvider" | "IssueLinkingProvider">;
  readonly " $fragmentType": "BusinessBoard_withWorkflow_query";
};
export type BusinessBoard_withWorkflow_query$key = {
  readonly " $data"?: BusinessBoard_withWorkflow_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBoard_withWorkflow_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "BusinessBoard_withWorkflow_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardFieldIdsProvider_query"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardContent_query"
    },
    {
      "kind": "FragmentSpread",
      "name": "IssueCreateProvider"
    },
    {
      "kind": "FragmentSpread",
      "name": "IssueLinkingProvider"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaycategoryfieldrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraJqlField",
          "kind": "LinkedField",
          "name": "jira_categoryField",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "kind": "ScalarField",
                "name": "fieldId"
              },
              "action": "THROW",
              "path": "jira_categoryField.fieldId"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "67cb9120de8cd51b8ba965bc7059174e";

export default node;

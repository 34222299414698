import uniqBy from 'lodash/uniqBy';
import type { User } from '@atlassian/jira-business-fetch-users/src/services/get-user/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	createActionsHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import type { CollabEditProvider, CollabParticipant } from '../../types.tsx';
import { getColorFromString } from '../../utils/colors/index.tsx';
import { MAX_CONNECTIONS_COUNT } from './constants.tsx';

export type Selection = {
	nodeId: string;
	user: User;
	color: string;
	sessionId: string;
};

type NodeSelection = {
	[nodeId: string]: Selection[];
};

type DropParams = {
	sourceNodeId: number;
	destinationNodeId?: number;
	destinationRelativeNodeId?: number;
	destinationColumnId?: number;
};

type State = {
	provider: CollabEditProvider | null;
	participants: CollabParticipant[];
	nodeSelection: NodeSelection;
};

type StoreActions = {
	getState: () => State;
	setState: (arg1: State) => void;
};

const updateUserInParticipants =
	(user: User) =>
	({ getState, setState }: StoreActions) => {
		const state = getState();

		setState({
			...state,
			participants: state.participants.map((participant) => {
				if (participant.clientId === user.userId) {
					return {
						...participant,
						avatar: user.avatar,
						name: user.name,
						email: user.email,
					};
				}

				return participant;
			}),
		});
	};

const participantsJoined =
	(joined: CollabParticipant[]) =>
	({ getState, setState }: StoreActions) => {
		const uniqueJoined = uniqBy(joined, ({ sessionId }) => sessionId);
		const newParticipantSessionIds = uniqueJoined.map(({ sessionId }) => sessionId);
		const state = getState();
		const { provider } = state;

		const newParticipants = [
			...state.participants.filter(
				({ sessionId }) => !newParticipantSessionIds.includes(sessionId),
			),
			...uniqueJoined,
		];
		// Collab team advised we should limit the number of max connections to control the load on collab service
		if (newParticipants.length >= MAX_CONNECTIONS_COUNT && provider) {
			// sort users by last active (last joined)
			const sortedUsers = newParticipants.sort((userA, userB) =>
				userA.lastActive > userB.lastActive ? 1 : -1,
			);
			const currentUser = sortedUsers.find((user) => user.sessionId === provider.sessionId);
			const lastActiveUser = sortedUsers[sortedUsers.length - 1];

			// kick the last joined user out
			setState({
				...state,
				participants: newParticipants.filter(
					({ sessionId }) => sessionId !== lastActiveUser.sessionId,
				),
			});
			if (lastActiveUser.sessionId === currentUser?.sessionId) {
				provider.destroy();
			}
			return;
		}

		setState({
			...state,
			participants: newParticipants,
		});
	};

const participantsLeft =
	(
		left: {
			sessionId: string;
		}[],
	) =>
	({ getState, setState }: StoreActions) => {
		const leftSessionIds = left.map(({ sessionId }) => sessionId);
		const state = getState();

		// cleanup user selection when they leave the session
		const newSelection: NodeSelection = {};
		Object.keys(state.nodeSelection).forEach((nodeIdKey) => {
			const selections = state.nodeSelection[nodeIdKey];

			if (selections.length > 0) {
				const filteredSelections = selections.filter(
					(selection) => !leftSessionIds.includes(selection.sessionId),
				);

				if (filteredSelections.length === 0) return;

				newSelection[nodeIdKey] = filteredSelections;
			}
		});

		setState({
			...state,
			participants: state.participants.filter(
				({ sessionId }) => !leftSessionIds.includes(sessionId),
			),
			nodeSelection: newSelection,
		});
	};

const setProvider =
	(provider: CollabEditProvider) =>
	({ getState, setState }: StoreActions) =>
		setState({ ...getState(), provider });

const sendNodeSelected =
	({ nodeId }: { nodeId: string | null }) =>
	({ getState }: { setState: (arg1: State) => void; getState: () => State }) => {
		if (nodeId == null) {
			return;
		}
		const { provider, participants } = getState();

		if (participants.length <= 1) {
			return;
		}

		if (provider) {
			provider.sendMessage({
				type: 'telepointer',
				sessionId: provider.sessionId,
				selection: { type: 'nodeSelection', anchor: JSON.stringify({ nodeId }) },
			});
		}
	};

const sendClearNodeSelection =
	({ nodeId, dropParams }: { nodeId: string | null; dropParams?: DropParams }) =>
	({ getState }: { setState: (arg1: State) => void; getState: () => State }) => {
		if (nodeId == null) {
			return;
		}

		const { provider, participants } = getState();

		if (participants.length <= 1) {
			return;
		}

		if (provider) {
			provider.sendMessage({
				type: 'telepointer',
				sessionId: provider.sessionId,
				selection: { type: 'nodeSelection', head: JSON.stringify({ nodeId, dropParams }) },
			});
		}
	};

const setNodeSelection =
	({ nodeId, sessionId, user }: { nodeId: string; sessionId: string; user: User }) =>
	({ setState, getState }: { setState: (arg1: State) => void; getState: () => State }) => {
		const { color } = getColorFromString(sessionId);
		const newSelection = { nodeId, user, color, sessionId };
		const state = getState();

		const dedupCurrentSelection =
			state.nodeSelection[nodeId] &&
			state.nodeSelection[nodeId].filter((selection) => selection.user.userId !== user.userId);
		const nodeSelection = dedupCurrentSelection
			? [...dedupCurrentSelection, newSelection]
			: [newSelection];

		// deduplicate selected nodes from this user, because each user can have only one selected node on the page
		// see JET-2794
		const dedupOtherSelections: NodeSelection = {};
		Object.keys(state.nodeSelection).forEach((nodeIdKey) => {
			// keep selections from all other users
			const selections = state.nodeSelection[nodeIdKey];

			if (selections.length > 0) {
				const filteredSelections = selections.filter(
					(selection) => selection.user.userId !== user.userId,
				);

				if (filteredSelections.length === 0) return;

				dedupOtherSelections[nodeIdKey] = filteredSelections;
			}
		});

		setState({
			...state,
			nodeSelection: {
				...dedupOtherSelections,
				[nodeId]: nodeSelection,
			},
		});
	};

const clearNodeSelection =
	({ nodeId }: { nodeId: string; dropParams?: DropParams; userId?: string }) =>
	({ setState, getState }: { setState: (arg1: State) => void; getState: () => State }) => {
		const state = getState();
		const newNodeSelection: NodeSelection = {};

		Object.keys(state.nodeSelection).forEach((nodeIdKey) => {
			if (nodeIdKey !== nodeId) {
				newNodeSelection[nodeIdKey] = state.nodeSelection[nodeIdKey];
			}
		});

		setState({
			...state,
			nodeSelection: newNodeSelection,
		});
	};

const actions = {
	setProvider,
	participantsLeft,
	participantsJoined,
	updateUserInParticipants,
	sendNodeSelected,
	sendClearNodeSelection,
	setNodeSelection,
	clearNodeSelection,
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	initialState: { provider: null, participants: [], nodeSelection: {} },
	actions,
	name: 'JWMCollabStore',
});

export const useCollabStore = createHook(Store);

export const useCollabStoreActions = createActionsHook(Store);

type NodeSelectionsProps = { nodeId: string };

export const useNodeSelections: ({ nodeId }: NodeSelectionsProps) => Selection[] | undefined =
	createStateHook(Store, {
		selector: (state: State, props: NodeSelectionsProps) => {
			const selections = state.nodeSelection[props.nodeId];

			return Array.isArray(selections) && selections.length ? selections : undefined;
		},
	});

import React, { useCallback, useMemo, useRef, useEffect, useState, memo } from 'react';
import { styled } from '@compiled/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ConnectionHandler, useMutation, graphql, useFragment } from 'react-relay';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { Box, xcss } from '@atlaskit/primitives';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { token } from '@atlaskit/tokens';
import RelayDataID from '@atlassian/relay-data-id';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { MutationErrorCollection } from '@atlassian/jira-mutation-error/src/mutation-error-collection.tsx';
import type { BoardContentColumnsOrderMutation } from '@atlassian/jira-relay/src/__generated__/BoardContentColumnsOrderMutation.graphql';
import { useFlagsService } from '@atlassian/jira-flags';
import { useCollabStoreActions } from '@atlassian/jira-business-collaboration/src/controllers/collab-store/index.tsx';
import {
	SELECTED_ISSUE_PARAM,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { ErrorPage } from '@atlassian/jira-business-error-page/src/index.tsx';
import {
	ExperienceAbort,
	ExperienceFailed,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useSimpleSearchJQL } from '@atlassian/jira-business-filters/src/controllers/simple-search/index.tsx';
import { ThemedFilterEmptyState } from '@atlassian/jira-business-filters/src/ui/themed-filter-empty-state/index.tsx';
import { ISSUE_DELETED_EVENT } from '@atlassian/jira-business-gqls-realtime/src/constants.tsx';
import type { GqlsRealtimeEvent } from '@atlassian/jira-business-gqls-realtime/src/types.tsx';
import { GqlsRealtime } from '@atlassian/jira-business-gqls-realtime/src/ui/index.tsx';
import { isIssueEvent } from '@atlassian/jira-business-gqls-realtime/src/utils.tsx';
import ModalIssueApp from '@atlassian/jira-business-issue-view/src/ui/modal-issue-app/index.tsx';
import { SidebarIssueView } from '@atlassian/jira-business-issue-view/src/ui/sidebar-issue-app/index.tsx';
import { PageVisibility } from '@atlassian/jira-business-page-visibility/src/index.tsx';
import { PerformanceAnalytics } from '@atlassian/jira-business-performance/src/ui/index.tsx';
import { RenderStartMark } from '@atlassian/jira-business-performance/src/ui/page-load/index.tsx';
import { SignupInviteModal } from '@atlassian/jira-business-signup-invite-modal/src/ui/index.tsx';
import { JWMSpaStatePageReady } from '@atlassian/jira-business-spa-state-page-ready/src/ui/index.tsx';
import { BUSINESS_BOARD } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { OnChangeCallback } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { ThemedSpinner } from '@atlassian/jira-project-theme-components/src/ui/ThemedSpinner.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import type {
	BoardContent_Inner_view$key,
	BoardContent_Inner_view$data,
} from '@atlassian/jira-relay/src/__generated__/BoardContent_Inner_view.graphql';
import type { BoardContent_Inner_query$key } from '@atlassian/jira-relay/src/__generated__/BoardContent_Inner_query.graphql';
import type { BoardContent_query$key } from '@atlassian/jira-relay/src/__generated__/BoardContent_query.graphql';
import type { BoardContent_view$key } from '@atlassian/jira-relay/src/__generated__/BoardContent_view.graphql';
import type { BoardContent_Inner_project$key } from '@atlassian/jira-relay/src/__generated__/BoardContent_Inner_project.graphql';
import type { BoardContent_project$key } from '@atlassian/jira-relay/src/__generated__/BoardContent_project.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import {
	EMPTY_BOARD_HEIGHT,
	COLUMN_FIXED_WIDTH,
	COLLAPSED_COLUMN_FIXED_WIDTH,
	COLUMN_CREATE_WIDTH,
	GAP_BETWEEN_COLUMNS,
	VIEW_EXPERIENCE,
	ISSUE_KEY_ID,
	NULL_GROUP_KEY,
} from '../../common/constants.tsx';
import type { Group } from '../../common/types.tsx';
import { useBoardData } from '../../controllers/board-data/BoardDataProvider.tsx';
import { useFilterStatus } from '../../controllers/board-filters/index.tsx';
import { useDropHandler } from '../../controllers/drag-and-drop/use-drop-handler/index.tsx';
import { useJWMBoardFeatures } from '../../controllers/features-context/index.tsx';
import { useHandleIssueEvents } from '../../controllers/handle-issue-events/index.tsx';
import { IssueGroupProvider } from '../../controllers/issue-group/index.tsx';
import { getUrlSettingsInput } from '../../common/url-settings-input.tsx';
import { EmptyBoardComponent } from './empty-state/index.tsx';
import Column from './column/BoardColumn.tsx';
import { BoardColumnCreate } from './column-create/BoardColumnCreate.tsx';
import { BoardMinimap } from './minimap/BoardMinimap.tsx';
import messages from './messages.tsx';

export type ColumnRendererProps = {
	group: Group;
	columnIndex: number;
};

const REALTIME_APP_ID = 'jira-business-board-view';
const experience = 'setBoardViewColumnsOrder';

type BoardContentInnerProps = {
	queryFragment: BoardContent_Inner_query$key;
	projectFragment: BoardContent_Inner_project$key;
	viewFragment: BoardContent_Inner_view$key;
	isEmpty: boolean;
	refresh: () => void;
};

type ColumnNode = NonNullable<
	NonNullable<NonNullable<BoardContent_Inner_view$data['columns']['edges'][number]>>['node']
>;

const MaybeUFOSegment = ({ children }: { children: React.ReactNode }) => {
	if (fg('platform_ufo_segment_list_mode')) {
		return (
			<UFOSegment name="business-board-column" mode="list">
				{children}
			</UFOSegment>
		);
	}
	return children;
};

const BoardContentInner = ({
	isEmpty,
	queryFragment,
	projectFragment,
	viewFragment,
	refresh,
}: BoardContentInnerProps) => {
	const boardContentRef = useRef<HTMLDivElement | null>(null);
	const [boardWrapper, setBoardWrapper] = useState<HTMLDivElement | null>(null);

	const data = useFragment(
		graphql`
			fragment BoardContent_Inner_query on Query {
				...BoardColumn_query
			}
		`,
		queryFragment,
	);

	const project = useFragment(
		graphql`
			fragment BoardContent_Inner_project on JiraProject {
				...BoardColumn_project
				...BoardColumnCreate_project
				projectStyle @required(action: THROW)
			}
		`,
		projectFragment,
	);

	const view = useFragment(
		graphql`
			fragment BoardContent_Inner_view on JiraBoardView {
				groupByConfig @required(action: THROW) {
					fieldId @required(action: THROW)
				}
				columns(first: null) @required(action: THROW) @connection(key: "business_board_columns") {
					...BoardColumnCreate_columns
					...BoardMinimap_columns
					totalCount @required(action: THROW)
					edges @required(action: THROW) {
						...BoardColumn_column
						node {
							__typename
							id
							collapsed
							... on JiraBoardViewStatusColumn {
								statuses {
									statusId @required(action: THROW)
								}
							}
							... on JiraBoardViewPriorityColumn {
								priority {
									priorityId @required(action: THROW)
								}
							}
							... on JiraBoardViewAssigneeColumn {
								user {
									accountId @required(action: THROW)
								}
							}
							... on JiraBoardViewCategoryColumn {
								category {
									optionId @required(action: THROW)
								}
							}
						}
					}
				}
				...BoardColumn_view
			}
		`,
		viewFragment,
	);

	const groupBy = view.groupByConfig.fieldId;
	const isColumnCreateEnabled =
		project.projectStyle === 'TEAM_MANAGED_PROJECT' && groupBy === GROUP_BY_STATUS;
	const columns = view.columns.edges.filter(Boolean);
	const numberOfColumns = view.columns.totalCount;
	const totalChildren = isColumnCreateEnabled ? numberOfColumns + 1 : numberOfColumns;

	const getColumnId = (column?: ColumnNode | null) => {
		if (column == null) {
			throw new Error('Column node is missing');
		}
		switch (column.__typename) {
			case 'JiraBoardViewAssigneeColumn':
				return column.user?.accountId ?? NULL_GROUP_KEY;
			case 'JiraBoardViewPriorityColumn':
				if (!column.priority) {
					throw new Error('Missing priority column fields');
				}
				return column.priority.priorityId;
			case 'JiraBoardViewCategoryColumn':
				return column.category?.optionId ?? NULL_GROUP_KEY;
			case 'JiraBoardViewStatusColumn':
				if (!column.statuses?.[0]) {
					throw new Error('Missing status column fields');
				}
				return column.statuses[0]?.statusId;
			default:
				throw new Error(`Unhandled column type: ${column.__typename}`);
		}
	};

	const estimateSize = useCallback(
		(index: number) => {
			const column = view.columns?.edges?.[index]?.node;
			if (column == null) {
				return COLUMN_CREATE_WIDTH;
			}
			return column.collapsed ? COLLAPSED_COLUMN_FIXED_WIDTH : COLUMN_FIXED_WIDTH;
		},
		[view.columns],
	);

	const getItemKey = useCallback(
		(index: number) => {
			const column = view.columns?.edges?.[index]?.node;
			if (column == null) {
				return 'column-create';
			}
			return column.id;
		},
		[view.columns],
	);

	const virtualizer = useVirtualizer({
		count: totalChildren,
		horizontal: true,
		getScrollElement: () => boardWrapper,
		estimateSize,
		gap: GAP_BETWEEN_COLUMNS,
		overscan: 0,
		getItemKey,
	});

	useEffect(() => {
		if (!boardWrapper) {
			return;
		}

		return autoScrollForElements({
			element: boardWrapper,
		});
	}, [boardWrapper]);

	const ColumnContent = virtualizer.getVirtualItems().map((virtualItem) => (
		<VirtualItemWrapper
			key={virtualItem.key}
			data-index={virtualItem.index}
			data-vc="business-board-column"
			start={virtualItem.start}
			ref={virtualizer.measureElement}
		>
			{virtualItem.index === numberOfColumns ? (
				<BoardColumnCreate
					columnsFragment={view.columns}
					projectFragment={project}
					refresh={refresh}
				/>
			) : (
				<MaybeUFOSegment>
					<IssueGroupProvider
						key={virtualItem.key}
						groupId={getColumnId(columns[virtualItem.index]?.node)}
					>
						<Column
							columnIndex={virtualItem.index}
							columnsNumber={numberOfColumns}
							queryFragment={data}
							projectFragment={project}
							columnFragment={columns[virtualItem.index]}
							viewFragment={view}
							refresh={refresh}
						/>
					</IssueGroupProvider>
				</MaybeUFOSegment>
			)}
		</VirtualItemWrapper>
	));

	return (
		<BoardWrapper
			ref={setBoardWrapper}
			data-testid="work-management-board.ui.board.board-wrapper"
			isEmpty={isEmpty}
		>
			<BoardContentWrapper
				data-vc="business-board-content-wrapper"
				ref={boardContentRef}
				totalSize={virtualizer.getTotalSize()}
			>
				{ColumnContent}
			</BoardContentWrapper>

			<BoardMinimap
				boardContentElement={boardContentRef.current}
				scrollElement={boardWrapper}
				columnsFragment={view.columns}
			/>
		</BoardWrapper>
	);
};

export type Props = {
	queryFragment: BoardContent_query$key;
	projectFragment: BoardContent_project$key;
	viewFragment: BoardContent_view$key;
	refresh: () => void;
};

const BoardContent = ({ queryFragment, projectFragment, viewFragment, refresh }: Props) => {
	const { featureView } = useJWMBoardFeatures();
	const { isFiltering } = useFilterStatus();
	const { isSearching } = useSimpleSearchJQL();
	const { showFlag } = useFlagsService();

	const data = useFragment(
		graphql`
			fragment BoardContent_query on Query
			@argumentDefinitions(
				withCategoryField: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-category-field.relayprovider"
				}
			) {
				...BoardContent_Inner_query
				jira_categoryField(cloudId: $cloudId) @include(if: $withCategoryField) {
					fieldId @required(action: THROW)
					displayName @required(action: THROW)
				}
			}
		`,
		queryFragment,
	);

	const project = useFragment(
		graphql`
			fragment BoardContent_project on JiraProject {
				...BoardContent_Inner_project
				projectId @required(action: THROW)
			}
		`,
		projectFragment,
	);

	const view = useFragment(
		graphql`
			fragment BoardContent_view on JiraBoardView {
				...BoardContent_Inner_view
				id @required(action: THROW)
				columns(first: null) @required(action: THROW) @connection(key: "business_board_columns") {
					edges @required(action: THROW) {
						node {
							id
						}
					}
				}
			}
		`,
		viewFragment,
	);

	const startExperience = useExperienceStart({
		experience,
		analyticsSource: 'jiraWorkManagementBoard',
	});
	const markExperienceSuccess = useExperienceSuccess({
		experience,
	});
	const markExperienceFailed = useExperienceFail({
		experience,
	});

	const [commit] = useMutation<BoardContentColumnsOrderMutation>(graphql`
		mutation BoardContentColumnsOrderMutation($input: JiraSetBoardViewColumnsOrderInput!) {
			jira_setBoardViewColumnsOrder(input: $input) {
				success
				boardView {
					...BusinessBoard_view
				}
				errors {
					message
					extensions {
						errorType
						statusCode
					}
				}
			}
		}
	`);

	const onColumnsReordered = useCallback(
		(reorderedColumnIds: string[]) => {
			startExperience();

			const handleFailure = (error: Error) => {
				markExperienceFailed('BoardContent jira_setBoardViewColumnsOrder mutation failed', error);
				showFlag({
					messageId: 'work-management-board.ui.board.show-flag.error.set-board-view-columns-order',
					messageType: 'transactional',
					title: messages.setBoardViewColumnsOrderFailedTitle,
					description: messages.setBoardViewColumnsOrderFailedMessage,
					type: 'error',
				});
			};

			commit({
				variables: {
					input: {
						columnIds: reorderedColumnIds,
						viewId: view.id,
						settings: getUrlSettingsInput(),
					},
				},
				optimisticUpdater: (store) => {
					const relayId = RelayDataID({ id: view.id }, 'JiraBoardView');
					const boardViewRecord = relayId && store.get(relayId);
					const connection =
						boardViewRecord &&
						ConnectionHandler.getConnection(boardViewRecord, 'business_board_columns');
					if (!connection) {
						return;
					}

					const existingEdges = connection.getLinkedRecords('edges') || [];
					const newEdges = reorderedColumnIds
						.map((columnId) =>
							existingEdges.find(
								(edge) => edge?.getLinkedRecord('node')?.getValue('id') === columnId,
							),
						)
						.filter(Boolean);
					connection.setLinkedRecords(newEdges, 'edges');
				},
				onError(error: Error) {
					handleFailure(error);
				},
				onCompleted(response) {
					if (response.jira_setBoardViewColumnsOrder?.success !== true) {
						const error = new MutationErrorCollection(
							response.jira_setBoardViewColumnsOrder?.errors,
						);
						handleFailure(error);
						return;
					}

					markExperienceSuccess();
				},
			});
		},
		[commit, markExperienceFailed, markExperienceSuccess, showFlag, startExperience, view.id],
	);

	const { filteredIssues, loading, error, refetch } = useBoardData();

	const { handleUpdatedIssue, handleDeletedIssue } = useHandleIssueEvents();

	const { sendClearNodeSelection } = useCollabStoreActions();

	const [selectedIssueKey, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE_PARAM);
	const prevSelectedIssueKey = usePreviousWithInitial(selectedIssueKey);
	const viewMode = useViewMode();
	let categoryField: {
		fieldId: string;
		displayName: string;
	} | null = null;
	if (fg('sv-357_relay_category_field')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		categoryField = useMemo(
			() =>
				data.jira_categoryField
					? {
							fieldId: data.jira_categoryField.fieldId,
							displayName: data.jira_categoryField.displayName,
						}
					: null,
			[data.jira_categoryField],
		);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { data: categoryFieldData } = useCategoryField();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		categoryField = useMemo(
			() =>
				categoryFieldData
					? {
							fieldId: categoryFieldData.id,
							displayName: categoryFieldData.name,
						}
					: null,
			[categoryFieldData],
		);
	}
	const isViewModeSideBar = viewMode === 'SIDEBAR';
	const columnIds = view.columns.edges.map((edge) => edge?.node?.id).filter(Boolean);

	useDropHandler({ initialPageLoading: loading, columnIds, onColumnsReordered, categoryField });

	const boardHasNoIssues = filteredIssues.length === 0;
	const isBoardEmptyAfterFilter = boardHasNoIssues && (isFiltering || isSearching) && !loading;
	const isBoardEmpty = boardHasNoIssues && !isFiltering && !loading;

	const onIssueModalClose = useCallback(() => {
		setSelectedIssueKey(undefined);
	}, [setSelectedIssueKey]);

	useEffect(() => {
		// when issueKey param is removed from URL we need to clear node selection
		if (selectedIssueKey == null && prevSelectedIssueKey != null) {
			const issue = filteredIssues.find(
				(item) => item.fields[ISSUE_KEY_ID].value === prevSelectedIssueKey,
			);
			if (issue) {
				const nodeId = `${view.id}/node/${issue.id}`;
				sendClearNodeSelection({ nodeId });
			}
		}
	}, [filteredIssues, prevSelectedIssueKey, selectedIssueKey, sendClearNodeSelection, view.id]);

	const onPageVisibilityChange = useCallback(
		({ isVisible }: { isVisible: boolean }) => {
			if (isVisible) {
				refresh();
				refetch();
			}
		},
		[refresh, refetch],
	);

	const onRealtimeEvent = useCallback(
		(event: GqlsRealtimeEvent) => {
			if (isIssueEvent(event)) {
				const issueId = Number(event.payload.issueId);
				if (event.type === ISSUE_DELETED_EVENT) {
					handleDeletedIssue(issueId);
				} else {
					handleUpdatedIssue(issueId);
				}
			}
		},
		[handleDeletedIssue, handleUpdatedIssue],
	);

	const onBentoChange: OnChangeCallback = useCallback(
		(event) => handleUpdatedIssue(Number(event.issueId)),
		[handleUpdatedIssue],
	);

	const onBentoDelete = useCallback(
		({ issueId }: { issueId: string | number }) => handleDeletedIssue(Number(issueId)),
		[handleDeletedIssue],
	);

	// Memoised to prevent flicker on JIRT updates
	const issueModal = useMemo(
		() =>
			selectedIssueKey != null && (
				<UFOSegment name="business-board-issue-modal">
					<ModalIssueApp
						issueKey={toIssueKey(selectedIssueKey)}
						onClose={onIssueModalClose}
						analyticsSource={BUSINESS_BOARD}
						onChange={onBentoChange}
						onDeleteSuccess={onBentoDelete}
					/>
				</UFOSegment>
			),
		[selectedIssueKey, onIssueModalClose, onBentoChange, onBentoDelete],
	);

	const ufoCustomData = useMemo(
		() => ({
			hasSelectedIssue: !!selectedIssueKey,
			isViewModeSideBar,
			isFiltering,
			isSearching,
			isBoardEmpty,
		}),
		[isBoardEmpty, isFiltering, isSearching, isViewModeSideBar, selectedIssueKey],
	);

	if (error != null) {
		const is404 = 'statusCode' in error && error.statusCode === 404;

		return (
			<>
				{is404 && <ExperienceAbort experience={VIEW_EXPERIENCE} error={error} />}
				{!is404 && <ExperienceFailed experience={VIEW_EXPERIENCE} error={error} />}
				<ErrorPage error={error} />
			</>
		);
	}

	return (
		<UFOSegment name="business-board-content">
			<Container>
				<UFOCustomData data={ufoCustomData} />

				<>
					{loading ? (
						<UFOLoadHold name="board-container-initial">
							<Box xcss={loadingWrapperStyles}>
								<ThemedSpinner />
							</Box>
						</UFOLoadHold>
					) : (
						<BoardContainer data-vc="business-board-container">
							<RenderStartMark view={featureView} />

							<BoardContentInner
								isEmpty={isBoardEmpty || isBoardEmptyAfterFilter}
								queryFragment={data}
								projectFragment={project}
								viewFragment={view}
								refresh={refresh}
							/>

							{isBoardEmpty ? (
								<EmptyStateContainer>
									<EmptyBoardComponent />
								</EmptyStateContainer>
							) : null}

							{isBoardEmptyAfterFilter ? (
								<EmptyStateContainer>
									<ThemedFilterEmptyState />
								</EmptyStateContainer>
							) : null}

							<JWMSpaStatePageReady />
						</BoardContainer>
					)}

					{isViewModeSideBar ? (
						<SidebarIssueView
							issueKey={selectedIssueKey}
							onClose={onIssueModalClose}
							onChange={onBentoChange}
							onIssueDeleteSuccess={onBentoDelete}
							analyticsSource={BUSINESS_BOARD}
						/>
					) : (
						issueModal
					)}

					<PerformanceAnalytics
						view={featureView}
						loading={loading}
						itemsCount={filteredIssues.length}
					/>

					<SignupInviteModal />

					<GqlsRealtime
						appId={REALTIME_APP_ID}
						entityId={project.projectId}
						onReceive={onRealtimeEvent}
					/>

					<PageVisibility onChange={onPageVisibilityChange} />
				</>
			</Container>
		</UFOSegment>
	);
};

export default memo(BoardContent);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	flex: 1,
	minHeight: 0,
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContainer = styled.div({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	flexShrink: '0',
	flexWrap: 'nowrap',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardWrapper = styled.div<{ isEmpty: boolean }>({
	width: '100%',
	overflowX: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	flex: ({ isEmpty }) => (isEmpty ? 0 : 1),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values
	minHeight: ({ isEmpty }) => (isEmpty ? `${EMPTY_BOARD_HEIGHT + 8}px` : 0),
	minWidth: 0,
	position: 'relative',
	color: token('color.text'),
	// We need to provide extra space to prevent the column drop indicator circle being clipped as overflow-y is set to auto due to overflow-x on this wrapper
	// as well as prevent the sibling create button being clipped
	// as well as the column drag over outline
	paddingTop: token('space.050'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.150'),
	marginTop: token('space.negative.050'),
	marginRight: token('space.negative.150'),
	marginBottom: token('space.negative.050'),
	marginLeft: token('space.negative.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContentWrapper = styled.div<{ totalSize: number }>({
	display: 'flex',
	alignItems: 'flex-start',
	flex: 1,
	flexFlow: 'row nowrap',
	position: 'relative',
	height: '100%',
	maxHeight: '100%',
	minWidth: 'fit-content',
	userSelect: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ totalSize }) => `${totalSize}px`,
	willChange: 'opacity',
});

const loadingWrapperStyles = xcss({
	alignItems: 'center',
	display: 'flex',
	flex: 1,
	justifyContent: 'space-around',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VirtualItemWrapper = styled.div<{
	start: number;
}>({
	height: '100%',
	position: 'absolute',
	top: 0,
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ start }) => `translateX(${start}px)`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyStateContainer = styled.div({
	flex: 1,
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
});

import { CATEGORY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useIssueTypesAndFields } from '../../services/issue-types-and-fields/index.tsx';

/**
 * @deprecated Do not use. Fetch category field from Relay instead.
 */
export const useCategoryField = () => {
	const {
		data: { fields },
		loading,
		error,
	} = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});

	const categoryField = fields.find((field) => field.type === CATEGORY_TYPE) ?? null;

	return {
		data: categoryField,
		loading,
		error,
	};
};

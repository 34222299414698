/* eslint-disable eslint-comments/no-restricted-disable */
/* eslint-disable @atlassian/react-entrypoint/no-imports-in-entrypoint-file */
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import CardActionsMenuContentQuery from '@atlassian/jira-relay/src/__generated__/CardActionsMenuContentQuery.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { getUrlSettingsInput } from '../../../../../../common/url-settings-input.tsx';

export const cardActionsMenuContentEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-business-board-card-actions-menu-content-entrypoint" */ './CardActionsMenuContent.tsx'
			),
	),
	getPreloadProps: ({
		cloudId,
		projectAri,
		viewId,
	}: {
		cloudId: string;
		projectAri: string;
		viewId: string;
	}) => ({
		queries: {
			mainQuery: {
				parameters: CardActionsMenuContentQuery,
				variables: {
					cloudId,
					projectAri,
					viewInput: {
						jiraBoardViewQueryInput: {
							viewId,
						},
						settings: getUrlSettingsInput(),
					},
					withCategoryField: fg('sv-357_relay_category_field'),
				},
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
			},
		},
	}),
});

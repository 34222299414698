import { useEffect } from 'react';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIssueTransitions } from '../../issue-transitions/index.tsx';
import { useMoveIssue } from '../../move-issue/index.tsx';
import { useCardDragDrop, useCancelConfirmCardDrop } from '../card-drag-drop-context/index.tsx';
import {
	isCard,
	isClosestEdgeAdjacentToSource,
	isColumn,
	isTransitionZone,
} from '../utils/index.tsx';

type Props = {
	initialPageLoading: boolean;
	columnIds: string[];
	onColumnsReordered: (reorderedColumnIds: string[]) => void;
	categoryField: {
		fieldId: string;
		displayName: string;
	} | null;
};

export const useDropHandler = ({
	initialPageLoading,
	columnIds,
	onColumnsReordered,
	categoryField,
}: Props) => {
	const moveIssue = useMoveIssue();
	const { cancelCardDrop, confirmCardDrop } = useCancelConfirmCardDrop();
	const { transitions } = useIssueTransitions();
	const { draggedIssue, dropTarget, sourceGroup } = useCardDragDrop();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(
		() =>
			monitorForElements({
				canMonitor: () => !initialPageLoading,
				onDrop: ({ location, source }) => {
					const sourceData = source.data;

					if (location.current.dropTargets[0]?.data == null) {
						if (isCard(sourceData)) {
							cancelCardDrop();
							fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
						}
						return;
					}

					const destinationData = location.current.dropTargets[0].data;

					// Dropping a card.
					if (isCard(sourceData) && draggedIssue != null) {
						// Dropping a card next to another card.
						if (isCard(destinationData)) {
							const closestEdgeOfTarget = extractClosestEdge(destinationData);
							const sourceIndex = sourceData.cardIndex;
							const destinationIndex = destinationData.cardIndex;
							const sourceColumnId = sourceData.columnId;
							const destinationColumnId = destinationData.columnId;

							// Return early if unable to determine the closest edge of the target.
							if (closestEdgeOfTarget == null) {
								cancelCardDrop();
								fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
								return;
							}

							// Return early if the card is dropped in the same place.
							if (sourceData.issueId === destinationData.issueId) {
								cancelCardDrop();
								fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
								return;
							}

							// Return early if the drop location would not result in a change in rank.
							// e.g. dropping a card near the bottom of the previous card or near the top of the next card.
							if (
								sourceColumnId === destinationColumnId &&
								isClosestEdgeAdjacentToSource(sourceIndex, destinationIndex, closestEdgeOfTarget)
							) {
								cancelCardDrop();
								fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
								return;
							}

							moveIssue({
								issue: draggedIssue,
								sourceGroup,
								destinationGroup: dropTarget.group,
								rank: {
									position: closestEdgeOfTarget === 'top' ? 'before' : 'after',
									relativeIssueId: destinationData.issueId,
								},
								transition: dropTarget.transition,
								categoryField,
							});
							confirmCardDrop();
						}
						// Dropping a card into a column or a transition zone.
						else if (isColumn(destinationData) || isTransitionZone(destinationData)) {
							if (!dropTarget.group) {
								return;
							}

							const transition = isColumn(destinationData)
								? dropTarget.transition
								: transitions.find((t) => t.transitionId === destinationData.transitionId);

							// prevents calling moveIssue when the dropTarget is updated with the wrong group when dragging a card across multiple transition zones within the same column
							// the moveIssue will be handled by the transition zone
							if (
								isTransitionZone(destinationData) &&
								transition?.toStatusId !== Number(dropTarget.group.id)
							) {
								return;
							}

							moveIssue({
								issue: draggedIssue,
								sourceGroup,
								destinationGroup: dropTarget.group,
								transition,
								categoryField,
							});
							confirmCardDrop();
						} else {
							cancelCardDrop();
							fireUIAnalytics(createAnalyticsEvent({}), 'card invalidDrop');
						}
					}

					// Dropping a column.
					if (isColumn(sourceData) && isColumn(destinationData)) {
						const sourceIndex = sourceData.index;
						const destinationIndex = destinationData.index;

						if (sourceIndex === destinationIndex) {
							return;
						}

						const closestEdgeOfTarget = extractClosestEdge(destinationData);

						// Return early if the drop location would not change the order of the columns.
						// e.g. dropping a column near the right edge of the previous column or near the left edge of the next column.
						if (isClosestEdgeAdjacentToSource(sourceIndex, destinationIndex, closestEdgeOfTarget)) {
							return;
						}

						const reorderedColumnIds: string[] = reorderWithEdge({
							list: columnIds,
							startIndex: sourceIndex,
							indexOfTarget: destinationIndex,
							closestEdgeOfTarget,
							axis: 'horizontal',
						});

						onColumnsReordered(reorderedColumnIds);
					}
				},
			}),
		[
			cancelCardDrop,
			confirmCardDrop,
			createAnalyticsEvent,
			draggedIssue,
			dropTarget.transition,
			initialPageLoading,
			moveIssue,
			transitions,
			dropTarget.group,
			sourceGroup,
			columnIds,
			onColumnsReordered,
			categoryField,
		],
	);
};

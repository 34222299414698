import { useCallback } from 'react';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import { useCollabStoreActions } from '@atlassian/jira-business-collaboration/src/controllers/collab-store/index.tsx';
import { SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants/src/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { ISSUE_KEY_ID } from '../../common/constants.tsx';
import type { BoardIssue } from '../../common/types.tsx';

export const useViewIssue = (nodeId: string | null) => {
	const ufoPressTracing = usePressTracing('jwm.board.issue-view-opened');
	const [, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE_PARAM);
	const { sendNodeSelected } = useCollabStoreActions();

	return useCallback(
		(issue: BoardIssue, withCmd: boolean) => {
			if (issue.id && issue.fields[ISSUE_KEY_ID].value) {
				const issueKey = issue.fields[ISSUE_KEY_ID].value;

				if (withCmd) {
					typeof window !== 'undefined' && window.open(`/browse/${issueKey}`, '_blank');
				} else {
					ufoPressTracing();
					setSelectedIssueKey(issueKey);
					if (nodeId) {
						sendNodeSelected({ nodeId });
					}
				}
			}
		},
		[nodeId, sendNodeSelected, setSelectedIssueKey, ufoPressTracing],
	);
};

/**
 * @generated SignedSource<<2ce2694195c30251763b254e455c1b4b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardColumn_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_query" | "BoardInlineCreate_query" | "BoardTransitionZones_query">;
  readonly " $fragmentType": "BoardColumn_query";
};
export type BoardColumn_query$key = {
  readonly " $data"?: BoardColumn_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardColumn_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardColumn_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardCard_query"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardInlineCreate_query"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardTransitionZones_query"
    }
  ],
  "type": "Query"
};

(node as any).hash = "cce86a5e60223cf7f6455822e667725b";

export default node;

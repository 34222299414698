import type {
	Transition,
	Workflow,
	IssueType,
} from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createContainer,
	createSelector,
	createStateHook,
	createStore,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';

type WorkflowStoreState = {
	issueTypes: IssueType[];
	transitions: Transition[];
	workflowId: string | null;
	workflows: Workflow[];
	workflowIssuesLastChangedTime?: string;
	workflowName: string | null;
};

const initialState: WorkflowStoreState = {
	issueTypes: [],
	transitions: [],
	workflows: [],
	workflowId: null,
	workflowName: null,
};

const setFetchResponse =
	({
		workflows,
		activeWorkflow,
		workflowIssuesLastChangedTime,
	}: {
		workflows: Workflow[];
		activeWorkflow: Workflow;
		workflowIssuesLastChangedTime?: string;
	}) =>
	async ({ setState }: StoreActionApi<WorkflowStoreState>) => {
		setState({
			issueTypes: activeWorkflow.issueTypes,
			transitions: activeWorkflow.transitions,
			workflows,
			workflowId: activeWorkflow.workflowId,
			workflowIssuesLastChangedTime,
			workflowName: activeWorkflow.workflowName,
		});
	};

const actions = {
	setFetchResponse,
};

export const WorkflowStoreContainer = createContainer();

const WorkflowStore = createStore({
	initialState,
	actions,
	name: 'WorkflowStore',
	containedBy: WorkflowStoreContainer,
});

const getInitialTransition = createSelector(
	(state: WorkflowStoreState) => state.transitions,
	(transitions) => transitions.find((transition) => transition.isInitial),
);

const getIssueTypeIds = createSelector(
	(state: WorkflowStoreState) => state.issueTypes,
	(issueTypes) => new Set(issueTypes.map((issueType) => issueType.id)),
);

export const useWorkflowStoreState = createStateHook(WorkflowStore);

export const useWorkflowStoreActions = createActionsHook(WorkflowStore);

export const useInitialTransition = createStateHook<
	WorkflowStoreState,
	typeof actions,
	ReturnType<typeof getInitialTransition>
>(WorkflowStore, {
	selector: getInitialTransition,
});

export const useIssueTypeIds = createStateHook<
	WorkflowStoreState,
	typeof actions,
	ReturnType<typeof getIssueTypeIds>
>(WorkflowStore, {
	selector: getIssueTypeIds,
});

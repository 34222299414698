/**
 * @generated SignedSource<<b204f1e49bed41113481d30cc8da4890>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BoardCard_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_CoverWrapper_query" | "BoardInlineCreate_query">;
  readonly " $fragmentType": "BoardCard_query";
};
export type BoardCard_query$key = {
  readonly " $data"?: BoardCard_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardCard_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "BoardCard_query",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "BoardCard_CoverWrapper_query"
    },
    {
      "kind": "FragmentSpread",
      "name": "BoardInlineCreate_query"
    }
  ],
  "type": "Query"
};

(node as any).hash = "980b30efc0158182f438c7c8adb4f4a6";

export default node;
